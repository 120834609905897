import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import StaticData from './StaticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  // faGripVertical,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import GeneralStudy from './RequirementGeneralStudy';
import Elective from './RequirementElective';
import Course from './RequirementCourse';
import CourseList from './RequirementCourseList';
import Recommended from './RequirementRecommended';
import Check from './RequirementCheck';

//pass the createWrappedData function to the child, pass dataFromParent to child, call back the dataToParent to parent
// modal=true, display modal, otherwise display as text
const Text = ({
  index,
  data,
  handleInputChange,
  handleDeleteComponent,
  showCourseListOption,
  isCourseListItem = false,
  handleShowAsCourseList = null,
}) => {
  const [showRecommended, setShowRecommended] = useState(false);

  const templateTextOptions = useSelector(
    (state) => state.template.templateTextOptions
  );

  const handleConnectorChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'connector', value);
  };

  const handleRequirementTypeChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'text', '');
    handleInputChange(index, 'customText', '');
    handleInputChange(index, 'division', '');
    handleInputChange(index, 'group', '');
    handleInputChange(index, 'groupIndex', '');
    handleInputChange(index, 'itemType', value);
  };

  const handleGroupChange = (e) => {
    const { value } = e.target;

    if (value !== 'custom_text') {
      const groupName = templateTextOptions[value].groupName;
      handleInputChange(index, 'group', groupName);
    }

    handleInputChange(index, 'groupIndex', value);
    handleInputChange(index, 'text', '');
    handleInputChange(index, 'customText', '');
  };

  const handleTextChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'customText', '');
    handleInputChange(index, 'text', value);
    handleInputChange(index, 'itemType', 'req_text');
  };

  const handleCustomTextChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'customText', value);
    handleInputChange(index, 'text', '');
    handleInputChange(index, 'group', '');
    handleInputChange(index, 'itemType', 'req_text');
  };

  const handleDivisionChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'division', value);
  };

  const handleAddRecommendedCourse = () => {
    setShowRecommended(true);
  };

  const handleRecommendedCoursesChange = useCallback(
    (data) => {
      handleInputChange(index, 'recommended', data);
    },
    [handleInputChange, index]
  );

  return (
    <>
      {data.itemType === 'req_text' || !data.itemType ? (
        <>
          {isCourseListItem && data.connector && (
            <div style={{ width: '80px' }} className="mx-5 pb-2">
              <select
                className="text-gray-7 p-1 w-100"
                value={data.connector ?? ''}
                name="connector"
                onChange={(e) => handleConnectorChange(e)}
                disabled={index === 0}
              >
                {StaticData.connectors.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="d-flex gap-2 pb-2">
            {/* Remove until reordering is finished */}
            {/* {!isCourseListItem && (
              <FontAwesomeIcon
                icon={faGripVertical}
                style={{ width: '15px' }}
                className="pt-4"
              />
            )} */}
            <div className="mt-4 me-1">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => handleDeleteComponent(index)}
                className={`deleteButton ${showCourseListOption ? 'fa-trash-active' : ''}`}
              />
            </div>

            {!isCourseListItem && (
              <div style={{ width: '132px' }}>
                <div className="fw-bold text-nowrap">Logical operator</div>
                <select
                  className="text-gray-7 p-1 w-100"
                  value={data.connector ?? ''}
                  name="connector"
                  onChange={(e) => handleConnectorChange(e)}
                  disabled={index === 0}
                >
                  {StaticData.connectors.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div>
              <div className="d-flex gap-2">
                <div>
                  <div className="fw-bold">Requirement type</div>
                  <div>
                    <select
                      style={{
                        width: '150px',
                      }}
                      className="text-gray-7 p-1"
                      value={'req_text'}
                      onChange={(e) => handleRequirementTypeChange(e)}
                    >
                      {showCourseListOption
                        ? StaticData.reqList.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                        : isCourseListItem
                          ? StaticData.courseListReqList.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          : StaticData.templateReqList.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                    </select>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="fw-bold">Text type</div>
                  <select
                    className="p-1"
                    style={{ width: '400px' }}
                    value={data.groupIndex ?? ''}
                    onChange={(e) => handleGroupChange(e)}
                  >
                    <option value="" disabled>
                      Text type
                    </option>
                    <option value="custom_text">Enter custom text</option>
                    {!!templateTextOptions.length && (
                      <option value="" disabled>
                        Select from presets:
                      </option>
                    )}
                    {templateTextOptions &&
                      templateTextOptions.map((textOptionGroup, index) => (
                        <option key={index} value={index}>
                          {textOptionGroup.groupName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {data.groupIndex === 'custom_text' ? (
                <div className="mb-1">
                  <div className="fw-bold">Custom text</div>
                  <textarea
                    className="p-1"
                    style={{ width: '600px' }}
                    name="customText"
                    value={data.customText ?? ''}
                    rows={1}
                    onChange={(e) => handleCustomTextChange(e)}
                  />
                </div>
              ) : (
                data.groupIndex !== '' && (
                  <div className="mb-2">
                    <div className="fw-bold">Text option</div>
                    <select
                      className="p-1"
                      style={{ width: '600px' }}
                      value={data.text ?? ''}
                      // onChange={(e) => handleInputChange(index, 'text', e.target.value)}
                      onChange={(e) => handleTextChange(e)}
                    >
                      <option value="" disabled>
                        Text option
                      </option>
                      {!!templateTextOptions[data.groupIndex]?.options.length &&
                        templateTextOptions[data.groupIndex].options.map(
                          (textOption, index) => (
                            <option key={index} value={`${textOption}`}>
                              {textOption}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                )
              )}
              {data.groupIndex !== '' && (
                <div className="d-flex gap-3 align-items-end">
                  <div>
                    <div className="fw-bold">Level (optional)</div>
                    <select
                      style={{
                        width: '178px',
                      }}
                      className="p-1"
                      value={data.division ?? ''}
                      onChange={(e) => handleDivisionChange(e)}
                    >
                      <option value="" disabled>
                        Level
                      </option>
                      {StaticData.divisionList.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-1">
                    <div
                      className="d-flex gap-1 align-items-center button-link"
                      onClick={() => handleAddRecommendedCourse()}
                      role="button"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <div>Recommended course</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {(data.recommended?.courses?.length > 0 || showRecommended) && (
            <div className="d-flex gap-2">
              <Recommended
                recommended={data.recommended}
                setRecommended={handleRecommendedCoursesChange}
              />
            </div>
          )}
        </>
      ) : data.itemType === 'req_course' ? (
        <Course
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_course_list' ? (
        <CourseList
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          handleShowAsCourseList={handleShowAsCourseList}
        />
      ) : data.itemType === 'req_elective' ? (
        <Elective
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_gs' ? (
        <GeneralStudy
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_check' ? (
        <Check
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : (
        <div>{/* Handle other cases if needed */}</div>
      )}
    </>
  );
};

export default Text;
