import React, { useCallback, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import StaticData from './StaticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  // faGripVertical,
} from '@fortawesome/free-solid-svg-icons';

import Course from './RequirementCourse';
import CourseList from './RequirementCourseList';
import Elective from './RequirementElective';
import Text from './RequirementText';
import Recommended from './RequirementRecommended';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Check from './RequirementCheck';

//pass the createWrappedData function to the child, pass dataFromParent to child, call back the dataToParent to parent
// modal=true, display modal, otherwise display as text
const GeneralStudy = ({
  index,
  data,
  handleInputChange,
  handleDeleteComponent,
  showCourseListOption,
  isCourseListItem = false,
  handleShowAsCourseList = null,
}) => {
  const [showRecommended, setShowRecommended] = useState(false);

  const handleConnectorChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'connector', value);
  };

  const handleRequirementTypeChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'gs', '');
    handleInputChange(index, 'division', '');
    handleInputChange(index, 'itemType', value);
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'gs', value);
  };

  const handleDivisionChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'division', value);
  };

  const handleAddRecommendedCourse = () => {
    setShowRecommended(true);
  };

  const handleRecommendedCoursesChange = useCallback(
    (data) => {
      handleInputChange(index, 'recommended', data);
    },
    [handleInputChange, index]
  );

  return (
    <>
      {data.itemType === 'req_gs' || !data.itemType ? (
        <>
          {isCourseListItem && data.connector && (
            <div style={{ width: '80px' }} className="mx-5 pb-2">
              <select
                className="text-gray-7 p-1 w-100"
                value={data.connector ?? ''}
                name="connector"
                onChange={(e) => handleConnectorChange(e)}
                disabled={
                  index === 0 &&
                  (!data.connector || data.connector.trim() === '')
                }
              >
                {StaticData.connectors.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="d-flex gap-2 pb-2">
            {/* Remove until reordering is finished */}
            {/* {!isCourseListItem && (
              <FontAwesomeIcon
                icon={faGripVertical}
                style={{ width: '15px' }}
                className="pt-4"
              />
            )} */}
            <div className="mt-4 me-1">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => handleDeleteComponent(index)}
                className={`deleteButton ${showCourseListOption ? 'fa-trash-active' : ''}`}
              />
            </div>
            {!isCourseListItem && (
              <div style={{ width: '132px' }}>
                <div className="fw-bold">Logical operator</div>
                <select
                  className="text-gray-7 p-1 w-100"
                  value={data.connector ?? ''}
                  name="connector"
                  onChange={(e) => handleConnectorChange(e)}
                  disabled={
                    index === 0 &&
                    (!data.connector || data.connector.trim() === '')
                  }
                >
                  {StaticData.connectors.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div>
              <div className="fw-bold">Requirement type</div>
              <div>
                <select
                  style={{
                    width: '150px',
                  }}
                  className="text-gray-7 p-1"
                  value={'req_gs'}
                  onChange={(e) => handleRequirementTypeChange(e)}
                >
                  {showCourseListOption
                    ? StaticData.reqList.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    : isCourseListItem
                      ? StaticData.courseListReqList.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))
                      : StaticData.templateReqList.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                </select>
              </div>
            </div>
            <div>
              <div className="fw-bold">General Studies</div>
              <select
                className="p-1"
                style={{ width: '340px' }}
                value={data.gs ?? ''}
                onChange={(e) => handleCategoryChange(e)}
              >
                <option value="" disabled>
                  Select general studies category
                </option>
                {StaticData.gsList.map((gs, index) => (
                  <option key={gs.value} value={gs.value}>
                    {gs.label} ({gs.value})
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex gap-3">
              <div>
                <div className="fw-bold">Level (optional)</div>
                <select
                  style={{ width: '178px' }}
                  className="p-1"
                  value={data.division ?? ''}
                  onChange={(e) => handleDivisionChange(e)}
                >
                  <option value="" disabled>
                    Level
                  </option>
                  {StaticData.divisionList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex align-items-end mb-1">
                <div
                  className="d-flex gap-1 align-items-center button-link"
                  onClick={() => handleAddRecommendedCourse()}
                  role="button"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <div>Recommended course</div>
                </div>
              </div>
            </div>
          </div>
          {(data.recommended?.courses?.length > 0 || showRecommended) && (
            <div className="d-flex gap-2">
              <Recommended
                recommended={data.recommended}
                setRecommended={handleRecommendedCoursesChange}
              />
            </div>
          )}
        </>
      ) : data.itemType === 'req_course' ? (
        <Course
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_course_list' ? (
        <CourseList
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          handleShowAsCourseList={handleShowAsCourseList}
        />
      ) : data.itemType === 'req_elective' ? (
        <Elective
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_text' ? (
        <Text
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_check' ? (
        <Check
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : (
        <div>{/* Handle other cases if needed */}</div>
      )}
    </>
  );
};

export default GeneralStudy;
