import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { checksheetCopy } from 'apis/checksheetAPIs';
import { Button } from '@asu/components-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import StaticData from './StaticData';
import useServiceAuth from 'auth/useServiceAuth';

const CopyProposedProgramForm = ({
  checksheet,
  programList,
  checksheetList,
  collegeList,
  departmentList,
  user,
  handleClose,
  refetchChecksheets,
}) => {
  const { getAccessToken } = useServiceAuth();

  const year = useSelector((state) => state.settings.year);

  const userCollege = user.college !== 'undefined' ? user.college : null;
  const userDepartments =
    user.department !== 'undefined' ? JSON.parse(user.department) : [];

  const [college, setCollege] = useState(userCollege);
  const [department, setDepartment] = useState(
    userDepartments.length === 1 ? userDepartments[0] : null
  );
  const [programName, setProgramName] = useState('');
  const [programType, setProgramType] = useState(null);
  const [copyFromYear, setCopyFromYear] = useState(year);
  const [toCatalogYear, setToCatalogYear] = useState(null);

  // Save new checksheet to database
  const { mutate: copyChecksheet } = useMutation({
    mutationFn: checksheetCopy,
    onSuccess: async (data, { token }) => {
      refetchChecksheets();
    },
  });

  const handleCollegeChange = (e) => {
    const { value } = e.target;

    setCollege(value);
    setDepartment(null);
  };

  const handleCopyChecksheet = async () => {
    const jsonData = {
      checksheetfrom: checksheet.id,
      acadplanto: null,
      acadsubplanto: null,
      collegeto: college,
      departmentto: department,
      nameto: programName,
      programtypeto: programType,
      yearto: toCatalogYear,
      proposed: true,
    };
    console.log('JSON data: ', jsonData);

    const token = await getAccessToken();
    copyChecksheet({ jsonData: jsonData, token: token });

    handleClose();
  };

  const handleSelectCopyFromYear = (e) => {
    setCopyFromYear(e.target.value);
  };

  return (
    <div className="p-6" style={{ width: '1182px' }}>
      <div className="fs-4 fw-bold mb-4">Copy checksheet</div>
      <div className="row justify-content-between mb-9">
        <div className="col-5">
          <div className="fs-5 fw-bold mb-2">Copy from</div>
          <div className="border border-gray-5 w-100 mb-3">
            <div className="border-start border-8 border-maroon ps-2 pe-3 py-3">
              <div className="fs-4 fw-bold">
                {checksheet.checksheetObj.checksheetName}
              </div>
              <div>
                {checksheet.checksheetObj.college !== 'OTHER'
                  ? collegeList[checksheet.checksheetObj.college].description
                  : 'Other'}
              </div>
              <div>{checksheet.checksheetObj.acadplan}</div>
            </div>
          </div>
          <div>
            <div className="fw-bold mb-1">Catalog year</div>
            <select
              value={copyFromYear}
              onChange={handleSelectCopyFromYear}
              style={{
                width: '100%',
              }}
              className="text-gray-7 p-1"
            >
              {StaticData.yearList.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center">
          <div
            className="bg-gray-2 lh-1 mt-10 rounded-circle"
            style={{
              height: 'fit-content',
              width: 'fit-content',
              padding: '16px 17px',
            }}
          >
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ width: '26px', height: '27px' }}
            />
          </div>
        </div>
        <div className="col-5">
          <div className="fs-5 fw-bold mb-2">To proposed</div>
          <div className="mb-5">
            <div className="fw-bold">College/school</div>
            {!!userCollege ? (
              <div>{collegeList[userCollege].description}</div>
            ) : (
              <select
                style={{ width: '100%' }}
                value={college ?? ''}
                onChange={(e) => handleCollegeChange(e)}
              >
                <option value="">Select college or school</option>
                {Object.entries(collegeList)
                  .filter(
                    ([code, obj]) =>
                      code !== 'COE' &&
                      code !== 'CGC' &&
                      code !== 'CLW' &&
                      code !== 'CTPH' &&
                      code !== 'CPROVOST'
                  )
                  .toSorted(([aCode, aObj], [bCode, bObj]) =>
                    aObj.alphaSortDescription.localeCompare(
                      bObj.alphaSortDescription
                    )
                  )
                  .map(([code, obj]) => (
                    <option key={code} value={code}>
                      {obj.alphaSortDescription}
                    </option>
                  ))}
                <option value="OTHER">Other</option>
              </select>
            )}
          </div>
          <div className="mb-5">
            <div className="fw-bold">Department</div>
            {userDepartments.length === 1 ? (
              <div>{departmentList[userDepartments[0]].description}</div>
            ) : (
              <select
                style={{ width: '100%' }}
                value={department ?? ''}
                onChange={(e) => setDepartment(e.target.value)}
                disabled={
                  college === 'OTHER' ||
                  (!!college &&
                    !!collegeList[college] &&
                    !collegeList[college].departments)
                }
              >
                <option value="">Select department</option>
                {!!college &&
                  college !== 'OTHER' &&
                  !!collegeList[college] &&
                  !!collegeList[college].departments &&
                  Object.entries(collegeList[college].departments)
                    .filter(([code, obj]) =>
                      !!userDepartments.length
                        ? userDepartments.includes(code)
                        : true
                    )
                    .toSorted(([aCode, aObj], [bCode, bObj]) =>
                      aObj.description.localeCompare(bObj.description)
                    )
                    .map(([code, obj]) => (
                      <option key={code} value={code}>
                        {obj.description}
                      </option>
                    ))}
              </select>
            )}
          </div>
          <div className="mb-5">
            <div className="fw-bold">Program name</div>
            <input
              type="text"
              placeholder="Enter program name"
              style={{ width: '100%' }}
              value={programName}
              onChange={(e) => setProgramName(e.target.value)}
            />
          </div>
          <div className="mb-5">
            <div className="fw-bold">Program type</div>
            <select
              value={programType ?? ''}
              onChange={(e) => setProgramType(e.target.value)}
              style={{ width: '100%' }}
            >
              <option value="">Select program type</option>
              <option value="UGRD">Undergraduate degree</option>
              <option value="MIN">Minor</option>
              <option value="CERT">Certificate</option>
            </select>
          </div>
          <div className="mb-5">
            <div className="fw-bold">Catalog year</div>
            <select
              value={toCatalogYear ?? ''}
              onChange={(e) => setToCatalogYear(e.target.value)}
              style={{ width: '100%' }}
            >
              <option value="">Select catalog year</option>
              {StaticData.yearList.map((year) => (
                <option key={year.value} value={year.value}>
                  {year.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <Button
          color="dark"
          label="Cancel"
          size="medium"
          onClick={handleClose}
        />
        <Button
          color="gold"
          label="Copy checksheet"
          size="medium"
          onClick={handleCopyChecksheet}
          disabled={
            !college ||
            (!department &&
              college !== 'OTHER' &&
              !!college &&
              !!collegeList[college] &&
              collegeList[college].departments) ||
            !programName ||
            !programType ||
            !toCatalogYear
          }
        />
      </div>
    </div>
  );
};

export default CopyProposedProgramForm;
