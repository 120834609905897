import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checksheetId: '',
  totalHours: 0,
  totalUpperDivisionHours: 0,
  totalHoursPerSubsection: {},
  totalUpperDivisionHoursPerSubsection: {},
  components: {},
  generalStudiesList: [],
  valid: false,
  numberOfRulesValid: 0,
};

export const validationSlice = createSlice({
  name: 'validation',
  initialState,
  reducers: {
    setValidationAll: (state, action) => {
      state.checksheetId = action.payload.checksheetId;
      state.totalHours = action.payload.totalHours;
      state.totalUpperDivisionHours = action.payload.totalUpperDivisionHours;
      state.totalHoursPerSubsection = action.payload.totalHoursPerSubsection;
      state.totalUpperDivisionHoursPerSubsection =
        action.payload.totalUpperDivisionHoursPerSubsection;
      state.components = action.payload.components;
      state.generalStudiesList = action.payload.generalStudiesList;
      state.valid = action.payload.valid;
      state.numberOfRulesValid = action.payload.numberOfRulesValid;

      return state;
    },
    clearValidation: (state) => {
      console.log('clearing validation');
      return { ...initialState };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setValidationAll, clearValidation } = validationSlice.actions;

export default validationSlice.reducer;
