import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import useServiceAuth from 'auth/useServiceAuth';
import {
  faChevronDown,
  faCircle,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StaticData from './StaticData';
// import { Button } from '@asu/components-core';
import { classNames } from 'utils';
import LoadingSkeleton from './LoadingSkeleton';
import { Button } from '@asu/components-core';
import { checksheetRequestRecall } from 'apis/checksheetAPIs';
import { useSelector } from 'react-redux';

const statusCircle = {
  DEPARTMENT: {
    color: 'text-gray-4',
  },
  COLLEGE: {
    color: 'text-gray-7',
  },
  PROVOST: {
    color: 'text-warning',
  },
  DARS: {
    color: 'text-info',
  },
  COMPLETE: {
    color: 'text-success',
  },
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const RecallRequestRow = ({
  checksheetId,
  recalledBy,
  noRecall,
  isSubplan,
  subplanRequests,
  showAllDetails,
  programList,
  checksheetList,
  proposedList,
  index,
  isLoading,
  isProposedLoading,
  selectedChecksheets,
  handleSelectChecksheet,
  userIsProvost,
  refetchRequests,
  refetchDarsRequests = null,
}) => {
  const { getAccessToken } = useServiceAuth();

  const campusesOffered = [];
  const owners = [];
  let mainOwner = {};

  const splitId = checksheetId.split('*');
  const acadPlanCode = splitId[2];
  const subplanCode = splitId[3] !== 'null' ? splitId[3] : null;
  const dateAndTime = new Date(recalledBy.date)
    .toLocaleDateString('en-us', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    })
    .split(', ');

  const checksheet = useMemo(
    () => checksheetList.find((checksheet) => checksheet.id === checksheetId),
    [checksheetList, checksheetId]
  );

  const proposedChecksheet = useMemo(
    () =>
      checksheet?.proposed
        ? proposedList?.find((proposed) => proposed.id === checksheetId)
        : null,
    [checksheet, proposedList, checksheetId]
  );

  let program = useMemo(
    () =>
      programList.find(
        (prog) =>
          !checksheet?.proposed &&
          prog.acadPlanCode === checksheetId.split('*')[2] &&
          (isSubplan
            ? prog.subplan?.acadSubPlanCode === checksheetId.split('*')[3]
            : prog.subplan === null)
      ),
    [checksheet, checksheetId, isSubplan, programList]
  );

  const degree = useMemo(
    () =>
      program?.degreeDescriptionShort
        ? program?.degreeDescriptionShort === 'CERT'
          ? ', Certificate'
          : `, ${program.degreeDescriptionShort}`
        : program?.acadPlanType === 'MIN'
          ? ', Minor'
          : null,
    [program]
  );

  const [showDetails, setShowDetails] = useState(showAllDetails);
  const [response, setResponse] = useState('');
  const [disableRequestActions, setDisableRequestActions] = useState(false);

  const departmentList = useSelector((state) => state.dataLists.departmentList);

  if (program?.campusesOffered && program?.campusesOffered.length > 0) {
    program.campusesOffered.forEach((campus) => {
      const obj = { ...campus };
      const campusFound = StaticData.campusUndergradList.find(
        (campusUndergrad) => campusUndergrad.value === campus.campusCode
      );
      if (campusFound?.label) obj.label = campusFound.label;
      campusesOffered.push(obj);
    });
  }

  if (program?.owners?.length > 0) {
    let highestPercent = 0;
    program.owners.forEach((programOwner) => {
      if (
        owners.filter(
          (owner) => owner.collegeAcadOrg === programOwner.collegeAcadOrg
        ).length === 0
      ) {
        owners.push({
          collegeAcadOrg: programOwner.collegeAcadOrg,
          collegeDescription: programOwner.collegeDescription,
          departmentAcadOrg: programOwner.departmentAcadOrg,
          departmentDescription: programOwner.departmentDescription,
        });
      }

      if (programOwner.percentOwned > highestPercent) {
        highestPercent = programOwner.percentOwned;
        mainOwner = programOwner;
      }
    });
  }

  const {
    mutate: requestChecksheetRecall,
    // error: updateChecksheetProgressError,
  } = useMutation({
    mutationFn: checksheetRequestRecall,
    onSuccess: () => {
      setTimeout(() => {
        refetchRequests();
        if (refetchDarsRequests !== null) refetchDarsRequests();
        setDisableRequestActions(false);
      }, 1000);
    },
    onError: (error) => {
      setDisableRequestActions(false);
    },
  });

  const handleRequestRecall = async (message, action) => {
    setDisableRequestActions(true);
    const jsonData = {
      action,
      note: message,
    };
    const token = await getAccessToken();

    await requestChecksheetRecall({
      id: checksheetId,
      jsonData: jsonData,
      token: token,
    });
  };

  // const handleSetChecked = (e) => {
  //   e.stopPropagation();
  //   handleSelectChecksheet(checksheetFound.id);
  // };

  useEffect(() => {
    setShowDetails(showAllDetails);
  }, [showAllDetails]);

  if (!checksheet?.proposed && !program)
    return <LoadingSkeleton key={index} width={'100%'} height={'65px'} />;

  return (
    <>
      {isLoading || isProposedLoading ? (
        <LoadingSkeleton key={index} width={'100%'} height={'65px'} />
      ) : (
        <div>
          <div
            key={acadPlanCode}
            className={classNames(
              noRecall && 'bg-gray-1 text-gray-5 pe-none',
              !noRecall && !!checksheet && userIsProvost && 'program-row',
              'row mx-0'
            )}
            // onClick={
            //   !!checksheetFound?.id && user.role.includes('PROVOST')
            //     ? (e) => handleSetChecked(e)
            //     : undefined
            // }
            // role={
            //   !!checksheetFound?.id && user.role.includes('PROVOST')
            //     ? 'button'
            //     : undefined
            // }
          >
            {/* <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              !!program.subplan && 'ms-7',
              'col-auto pt-2'
            )}
            style={{ width: '5%' }}
          >
            {!!checksheetFound?.id && user.role.includes('PROVOST') && (
              <input
                type="checkbox"
                className="program-row-checkbox"
                id={`checkboxChecksheet-${
                  program.subplan
                    ? program.subplan.acadSubPlanCode
                    : program.acadPlanCode
                }`}
                data-ga-input="checkbox"
                data-ga-input-name="onclick"
                data-ga-input-event="select"
                data-ga-input-action="click"
                data-ga-input-region="main content"
                data-ga-input-section={`Select checksheet for ${
                  program.subplan
                    ? program.subplan.acadSubPlanCode
                    : program.acadPlanCode
                }`}
                checked={selectedChecksheets.includes(checksheetFound.id)}
                onChange={(e) => handleSetChecked(e)}
                onClick={(e) => e.stopPropagation()}
              />
            )}
          </div> */}
            <div
              className={classNames(
                (!!index || isSubplan) && 'border-top border-gray-3',
                !!subplanCode && 'ms-7',
                'col-auto ps-1 py-2'
              )}
              style={{
                width: '12%',
              }}
            >
              {!noRecall && (
                <div>
                  <div>{dateAndTime[0]}</div>
                  <div>{dateAndTime[1]}</div>
                </div>
              )}
            </div>
            <div
              className={classNames(
                (!!index || isSubplan) && 'border-top border-gray-3',
                'col-auto fw-bold py-2'
              )}
              style={{
                width: isSubplan ? 'calc(23% - 3.5rem)' : '23%',
              }}
            >
              {!!checksheet ? (
                <Link
                  className="underline-hover text-gray-7"
                  to={`/checksheet?id=${checksheet.id}&showHistory=true`}
                >
                  {!checksheet.proposed ? (
                    <span>
                      {program.subplan
                        ? program.subplan.description
                        : program.acadPlanDescription}
                      {degree}
                    </span>
                  ) : (
                    <span>{proposedChecksheet?.checksheetName}</span>
                  )}
                </Link>
              ) : (
                <div className="pe-none text-gray-5">
                  {program.subplan
                    ? program.subplan.description
                    : program.acadPlanDescription}
                  {degree}
                </div>
              )}
            </div>
            <div
              className={classNames(
                (!!index || isSubplan) && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '15%' }}
            >
              {subplanCode ?? acadPlanCode}
            </div>
            <div
              className={classNames(
                (!!index || isSubplan) && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '17%' }}
            >
              {
                !noRecall &&
                  (!checksheet?.proposed
                    ? mainOwner.departmentDescription
                    : departmentList[proposedChecksheet.department].description)
                // owners.map((owner, index) => (
                //   <div
                //     key={owner.departmentAcadOrg}
                //     className={classNames(index !== owners.length - 1 && 'mb-1')}
                //   >
                //     {owner.departmentDescription}
                //   </div>
                // ))
              }
            </div>
            <div
              className={classNames(
                (!!index || isSubplan) && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '21%' }}
            >
              {!noRecall &&
                (isLoading || isProposedLoading ? (
                  <div>Loading...</div>
                ) : (
                  <div>
                    <div>
                      {!!checksheet?.status ? (
                        <div className="d-flex gap-1">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className={statusCircle[checksheet.status].color}
                            width={'12px'}
                            style={{
                              marginTop: '4px',
                            }}
                          />
                          {StaticData.statusArray[checksheet.status]}
                        </div>
                      ) : (
                        <span style={{ marginLeft: '20px' }}>
                          No checksheet exists
                        </span>
                      )}
                    </div>
                    {!!checksheet?.lockedBy &&
                      !!Object.keys(checksheet.lockedBy).length && (
                        <div
                          className="d-flex gap-1 align-items-center px-1 mt-1 bg-gray-2 rounded-1"
                          style={{ width: 'fit-content' }}
                        >
                          <FontAwesomeIcon
                            icon={faLock}
                            className="text-gray-6"
                          />
                          <div>Locked by {checksheet.lockedBy.asurite}</div>
                        </div>
                      )}
                  </div>
                ))}
            </div>
            <div
              className={classNames(
                (!!index || isSubplan) && 'border-top border-gray-3',
                'col-auto d-flex justify-content-end py-2 pe-1'
              )}
              style={{ width: '12%' }}
            >
              {!noRecall && (
                <div
                  className="button-link"
                  onClick={() => setShowDetails(!showDetails)}
                  role="button"
                >
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={classNames(showDetails && 'rotate-180', 'me-1')}
                  />
                  {!showDetails ? 'Show details' : 'Hide details'}
                </div>
              )}
            </div>
          </div>
          {!noRecall && showDetails && (
            <div className="row mx-0 pt-2">
              <div
                className="col-auto"
                style={{
                  width: '12%',
                }}
              />
              <div className="col-auto flex-grow-1">
                <div className="mb-4">
                  <div className="fw-bold">Recall requested by</div>
                  <div>
                    {recalledBy.role ? capitalize(recalledBy.role) : ''},{' '}
                    {recalledBy.asurite}
                  </div>
                </div>
                <div className="mb-4">
                  <div className="fw-bold">Message</div>
                  <div
                    className={classNames(
                      !recalledBy.note && 'fst-italic text-gray-6',
                      'px-2 py-1 border border-gray-2 bg-gray-1'
                    )}
                    style={{ width: '70%' }}
                  >
                    {!!recalledBy.note
                      ? recalledBy.note
                      : 'No message included'}
                  </div>
                </div>
                <div className="mb-2">
                  <div className="fw-bold">Response (optional)</div>
                  <input
                    type="text"
                    placeholder="Write a response here"
                    className="px-2 py-1 border border-gray-5"
                    style={{ width: '70%' }}
                    onChange={(e) => setResponse(e.target.value)}
                  />
                </div>
                <div className="d-flex gap-1 mb-4">
                  <Button
                    label="Approve"
                    color="gold"
                    onClick={() => handleRequestRecall(response, 'approve')}
                    disabled={disableRequestActions}
                  />
                  <Button
                    label="Deny"
                    color="dark"
                    onClick={() => handleRequestRecall(response, 'deny')}
                    disabled={disableRequestActions}
                  />
                </div>
              </div>
            </div>
          )}
          {!!subplanRequests?.length && (
            <div className="d-flex mt-1 ms-7">
              <div
                className="px-1 text-success-custom bg-success-custom"
                style={{
                  fontSize: '14px',
                }}
              >
                Active subplans
              </div>
            </div>
          )}
        </div>
      )}
      {subplanRequests?.map((request, index) => (
        <RecallRequestRow
          key={request.checksheet}
          checksheetId={request.checksheet}
          recalledBy={request.recalledBy}
          noRecall={false}
          isSubplan={true}
          subplanRequests={[]}
          showAllDetails={showAllDetails}
          programList={programList}
          checksheetList={checksheetList}
          index={index}
          isLoading={isLoading}
          isProposedLoading={isProposedLoading}
          selectedChecksheets={selectedChecksheets}
          handleSelectChecksheet={handleSelectChecksheet}
          userIsProvost={userIsProvost}
        />
      ))}
    </>
  );
};

export default RecallRequestRow;
