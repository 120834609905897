import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  faTriangleExclamation,
  faCheckCircle,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useServiceAuth from 'auth/useServiceAuth';
import { submitPlanExtensions } from 'apis/adminAPIs';
// import StaticData from './StaticData';
import { Button } from '@asu/components-core';
import { classNames } from 'utils';

const PlanExtensionProgramRow = ({
  program,
  checksheetList,
  filters,
  index,
  refetchChecksheets, // Function to refresh data
  refetchPlanExtensionsProgress,
  isLoading,
  user,
}) => {
  const { getAccessToken } = useServiceAuth();

  const userIsProvost = user?.role?.includes('PROVOST');

  const [loading, setLoading] = useState(false); // Submission loading state
  const [resubmitting, setResubmitting] = useState(false); // Resubmission loading state
  const [checksheetFound, setChecksheetFound] = useState(null);
  const [planExtensionsStatus, setPlanExtensionsStatus] = useState(null);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  // const [checksheetStatus, setChecksheetStatus] = useState(null);
  const [submitted, setSubmitted] = useState(false); // Track if submission has occurred
  const [copied, setCopied] = useState(null); // Track which URL is copied

  const degree =
    program.acadPlanType === 'CER'
      ? ', Certificate'
      : program.acadPlanType === 'MIN'
        ? ', Minor'
        : program.acadPlanType && program.degreeDescriptionShort
          ? `, ${program.degreeDescriptionShort}`
          : null;

  const handleSubmitById = async (checksheetId, isResubmitting = false) => {
    try {
      console.log(
        isResubmitting ? 'Resubmitting...' : 'Submitting...',
        checksheetId
      );
      if (isResubmitting) {
        setResubmitting(true); // Set resubmitting state
      } else {
        setLoading(true); // Set loading state
        setSubmitted(true); // Hide submit button
      }

      const token = await getAccessToken();
      const jsonData = { id: checksheetId };
      await submitPlanExtensions({ jsonData, token });

      // Simulate a delay for refreshing data after 2 seconds
      setTimeout(() => {
        refetchChecksheets(); // Re-fetch data after submission
        refetchPlanExtensionsProgress(); // Re-fetch data after submission
        setLoading(false);
        setResubmitting(false);
      }, 5000);
    } catch (error) {
      console.error('Submission failed:', error);
      setLoading(false);
      setResubmitting(false);
    }
  };

  const handleCopy = (url, id) => {
    if (!url) return; // Prevent copying empty URLs
    navigator.clipboard.writeText(url); // Copy to clipboard
    setCopied(id); // Set copied state for feedback
    setTimeout(() => setCopied(null), 2000); // Reset after 2 seconds
  };

  useEffect(() => {
    const found = checksheetList.find(
      (checksheet) =>
        checksheet.id.split('*')[2] === program.acadPlanCode &&
        (!!program.subplan
          ? checksheet.id.split('*')[3] === program.subplan.acadSubPlanCode
          : checksheet.id.split('*')[3] === 'null')
    );

    setChecksheetFound(found);
    setPlanExtensionsStatus(found?.planExtensionsStatus?.status);

    // if (found?.status) {
    //   const statusFound = StaticData.statusList.find(
    //     (status) => status.value === found.status
    //   );

    //   // if (!!statusFound) setChecksheetStatus(statusFound.label);
    // }

    const isDisabled =
      !!filters.length &&
      ((!!found?.status && !filters.includes(found.status)) ||
        (!found && !filters.includes('NONE')));

    setIsFilterDisabled(isDisabled);
  }, [checksheetList, filters, program, degree]);

  if (!program) return <div>Loading...</div>;

  return (
    <>
      {!!checksheetFound && userIsProvost && (
        <div>
          <div
            key={program.acadPlanCode}
            className={classNames(
              isFilterDisabled && 'bg-gray-1 text-gray-6 pe-none',
              !!checksheetFound && userIsProvost && 'program-row',
              'row mx-0'
            )}
          >
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto ps-0 fw-bold py-2'
              )}
              style={{ width: '23%' }}
            >
              {!!checksheetFound && (
                <Link
                  className="underline-hover text-gray-7"
                  to={`/checksheet?id=${checksheetFound.id}`}
                >
                  {program.programDescr}
                  {degree}
                </Link>
              )}
            </div>
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '10%' }}
            >
              {program.acadPlanCode}
            </div>
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '10%' }}
            >
              {program.subplan?.acadSubPlanCode}
            </div>
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '9%' }}
            >
              {program.planExtensionType}
            </div>
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '9%' }}
            >
              {program.planExtensionDefaultFlag}
            </div>
            {/* Plan Extension URL with Copy Button */}
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto py-2 d-flex align-items-center'
              )}
              style={{ width: '24%', paddingRight: '10px' }}
            >
              {program.planExtensionUrl ? (
                <>
                  <div className="d-flex flex-column">
                    <span
                      style={{
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {program.planExtensionUrl}
                    </span>
                    <button
                      className="text-maroon text-decoration-underline border-0 bg-transparent p-0 mt-1 text-start"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        handleCopy(
                          program.planExtensionUrl,
                          program.acadPlanCode
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faLink} className="me-1" /> Copy
                    </button>
                    {copied === program.acadPlanCode && (
                      <span className="text-success mt-1">Copied!</span>
                    )}
                  </div>
                </>
              ) : (
                <span style={{ marginLeft: '20px' }}>No URL Available</span>
              )}
            </div>

            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto d-flex gap-3 pe-1'
              )}
              style={{
                height: 'fit-content',
                width: '15%',
                padding: '12px 20px 8px',
              }}
            >
              {loading ? (
                <div className="text-danger-custom fw-bold">Submitting...</div>
              ) : planExtensionsStatus === '' ||
                planExtensionsStatus === null ||
                planExtensionsStatus === undefined ? (
                !submitted && (
                  <Button
                    label="Submit"
                    color="maroon"
                    size="small"
                    onClick={() => handleSubmitById(checksheetFound.id)}
                  />
                )
              ) : planExtensionsStatus === 'SUCCESS' ? (
                <div>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success-custom"
                  />{' '}
                  Success
                </div>
              ) : (
                <div>
                  <div className="text-danger-custom fw-bold">
                    <FontAwesomeIcon icon={faTriangleExclamation} /> Failed to
                    submit
                  </div>
                  <div style={{ fontSize: '12px' }}>{planExtensionsStatus}</div>
                  <div>
                    {resubmitting ? (
                      <div className="text-danger-custom fw-bold">
                        Resubmitting...
                      </div>
                    ) : (
                      <Button
                        label="Resubmit"
                        color="maroon"
                        size="small"
                        onClick={() =>
                          handleSubmitById(checksheetFound.id, true)
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlanExtensionProgramRow;
