import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { checksheetCopy, checksheetSave } from 'apis/checksheetAPIs';
import { setTemplateList } from 'state/slices/dataListsSlice';
import { Button } from '@asu/components-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuildingColumns,
  faLocationDot,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import StaticData from './StaticData';
import { classNames } from 'utils';
import useServiceAuth from 'auth/useServiceAuth';
import { templateGetAllByYear } from 'apis/templateAPIs';

const programTypes = {
  UGRD: 'undergrad',
  MIN: 'minor',
  CERT: 'cert',
};

const proposedString = '(proposed) ';

const SearchProposed = ({ proposed, college }) => {
  return (
    <>
      <div className="d-flex gap-2 justify-content-between">
        <div className="fw-bold" style={{ fontSize: '18px' }}>
          (PROPOSED) {proposed.checksheetObj.checksheetName}
        </div>
        <div style={{ marginTop: '3px' }}>
          {proposed.checksheetObj.acadplan}
        </div>
      </div>
      {!!college?.description && (
        <div className="d-flex gap-1 align-items-center">
          <FontAwesomeIcon
            icon={faBuildingColumns}
            className="text-gray-6"
            width={'16px'}
          />
          {college.description}
        </div>
      )}
    </>
  );
};

const SearchProgram = ({ program }) => {
  const degree = program.degreeDescriptionShort
    ? program.degreeDescriptionShort === 'CERT'
      ? ', Certificate'
      : `, ${program.degreeDescriptionShort}`
    : program.acadPlanType === 'MIN'
      ? ', Minor'
      : null;

  const owners = [];
  if (program.owners.length > 0) {
    program.owners.forEach((programOwner) => {
      if (
        owners.filter(
          (owner) => owner.collegeAcadOrg === programOwner.collegeAcadOrg
        ).length === 0
      ) {
        owners.push({
          collegeAcadOrg: programOwner.collegeAcadOrg,
          collegeDescription: programOwner.collegeDescription,
        });
      }
    });
  }

  const campusesOffered = [];
  if (program.campusesOffered && program.campusesOffered.length > 0) {
    program.campusesOffered.forEach((campus) => {
      const obj = { ...campus };
      const campusFound = StaticData.campusUndergradList.find(
        (campusUndergrad) => campusUndergrad.value === campus.campusCode
      );
      if (campusFound?.label) obj.label = campusFound.label;
      campusesOffered.push(obj);
    });
  }

  return (
    <>
      <div className="d-flex gap-2 justify-content-between">
        <div className="fw-bold" style={{ fontSize: '18px' }}>
          {program.acadPlanDescription}
          {/* {program.subplan && ` - ${program.subplan.description}`} */}
          {degree}
        </div>
        <div style={{ marginTop: '3px' }}>{program.acadPlanCode}</div>
      </div>
      {program.subplan && (
        <div className="d-flex gap-2 justify-content-between">
          <div className="fw-bold">
            {program.subplan.description}
            <span className="subplan-tag ms-1">Subplan</span>
          </div>
          <div style={{ marginTop: '3px' }}>
            {program.subplan.acadSubPlanCode}
          </div>
        </div>
      )}
      <div className="d-flex gap-1 align-items-center">
        <FontAwesomeIcon
          icon={faBuildingColumns}
          className="text-gray-6"
          width={'16px'}
        />
        {owners.map((owner, index, array) => (
          <Fragment key={owner.collegeAcadOrg}>
            {owner.collegeDescription}
            {index !== array.length - 1 && ', '}
          </Fragment>
        ))}
      </div>
      <div className="d-flex gap-1 align-items-center">
        <FontAwesomeIcon
          icon={faLocationDot}
          className="text-gray-6"
          width={'16px'}
        />
        {campusesOffered.map((campus, index, array) => (
          <Fragment key={campus.campusCode}>
            {campus.label}
            {index !== array.length - 1 && ', '}
          </Fragment>
        ))}
      </div>
    </>
  );
};

const NewProposedProgramForm = ({
  programList,
  proposedList,
  collegeList,
  handleClose,
  user,
  year,
  refetchChecksheets,
}) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const userCollege = user.college !== 'undefined' ? user.college : null;
  const userDepartments =
    user.department !== 'undefined' ? JSON.parse(user.department) : [];

  const [searchString, setSearchString] = useState('');
  const [college, setCollege] = useState(userCollege);
  const [department, setDepartment] = useState(
    userDepartments.length === 1 ? userDepartments[0] : null
  );
  const [programName, setProgramName] = useState('');
  const [programType, setProgramType] = useState(null);
  const [catalogYear, setCatalogYear] = useState(year);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedProgramDegree, setSelectedProgramDegree] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [selectedChecksheet, setSelectedChecksheet] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // const [error, setError] = useState(null);

  const checksheetList = useSelector((state) => state.dataLists.checksheetList);
  const templateList = useSelector((state) => state.dataLists.templateList);
  // const collegeList = useSelector((state) => state.dataLists.collegeList);
  const departmentList = useSelector((state) => state.dataLists.departmentList);

  const { data: templateData, isSuccess } = useQuery({
    queryKey: ['templates', catalogYear],
    queryFn: async () => {
      const token = await getAccessToken();
      return templateGetAllByYear({ token, year: catalogYear });
    },
    enabled: !!catalogYear,
  });

  const {
    mutate: saveChecksheet,
    // error: saveChecksheetError,
    // isError: saveChecksheetIsError,
  } = useMutation({
    mutationFn: checksheetSave,
    onSuccess: async (data, { token }) => {
      refetchChecksheets();
    },
  });

  // Save new checksheet to database
  const { mutate: copyChecksheet } = useMutation({
    mutationFn: checksheetCopy,
    onSuccess: async (data, { token }) => {
      refetchChecksheets();
    },
  });

  const handleCollegeChange = (e) => {
    const { value } = e.target;

    setCollege(value);
    setDepartment(null);
  };

  const handleSetProgramType = (e) => {
    const { value } = e.target;
    setProgramType(value);

    if (selectedRadio === 'blankChecksheet') {
      if (value === 'UGRD') setSelectedTemplate(null);
      else {
        const allMinorTemplates = templateList.filter(
          (template) => template.details.programType === 'minor'
        );
        const allCertificateTemplates = templateList.filter(
          (template) => template.details.programType === 'cert'
        );

        if (value === 'MIN' && allMinorTemplates.length === 1)
          setSelectedTemplate(allMinorTemplates[0].id);
        else if (value === 'CERT' && allCertificateTemplates.length === 1)
          setSelectedTemplate(allCertificateTemplates[0].id);
        else setSelectedTemplate(null);
      }
    }
  };

  const handleCreateChecksheet = async () => {
    if (selectedRadio === 'blankChecksheet') {
      const jsonData = {
        year: catalogYear,
        templateId: selectedTemplate,
        checksheetName: programName,
        programType: programType,
        acadplan: null,
        acadsubplan: null,
        college: college,
        department: department,
        componentRequirements: {},
        courseLists: {},
        proposed: true,
      };

      const token = await getAccessToken();
      await saveChecksheet({
        jsonData: jsonData,
        token: token,
      });

      handleClose();
    } else if (selectedChecksheet) {
      const jsonData = {
        checksheetfrom: selectedChecksheet.id,
        acadplanto: null,
        acadsubplanto: null,
        collegeto: college,
        departmentto: department,
        nameto: programName,
        programtypeto: programType,
        yearto: catalogYear,
        proposed: true,
      };
      console.log('JSON data: ', jsonData);

      const token = await getAccessToken();
      copyChecksheet({ jsonData: jsonData, token: token });

      handleClose();
    } else {
      alert('Program not selected');
    }
  };

  const handleSelectRadio = (e) => {
    setSelectedRadio(e.target.value);
    setSelectedTemplate(null);
    setSelectedChecksheet(null);
  };

  const handleSelectChecksheet = (program, proposed = false) => {
    let cs;

    if (!proposed) {
      cs = checksheetList.find(
        (checksheet) =>
          checksheet.id.split('*')[2] === program.acadPlanCode &&
          (!!program.subplan
            ? checksheet.id.split('*')[3] === program.subplan.acadSubPlanCode
            : checksheet.id.split('*')[3] === 'null')
      );

      setSelectedProgram(program);
    } else {
      cs = program;
      setSelectedProgram({
        acadPlanCode: program.checksheetObj.acadplan,
        acadPlanDescription: program.checksheetObj.checksheetName,
        college: program.checksheetObj.college,
        proposed: true,
      });
    }

    console.log(cs);
    console.log(program);
    setSelectedChecksheet(cs);
  };

  const handleClearSelectedChecksheet = () => {
    setSearchString('');
    setSelectedChecksheet(null);
  };

  useEffect(() => {
    if (isSuccess) {
      const next = [];
      templateData.forEach((template) => {
        if (template['details']) {
          const jsonObject = JSON.parse(template['details'].S);
          const obj = {
            id: template['sk'].S,
            details: jsonObject,
            templateObj: template,
          };
          next.push(obj);
        }
      });

      dispatch(setTemplateList(next));
    }
  }, [templateData, dispatch, isSuccess]);

  useEffect(() => {
    if (selectedProgram) {
      const dds = selectedProgram.degreeDescriptionShort
        ? selectedProgram.degreeDescriptionShort === 'CERT'
          ? ', Certificate'
          : `, ${selectedProgram.degreeDescriptionShort}`
        : selectedProgram.acadPlanType === 'MIN'
          ? ', Minor'
          : null;

      setSelectedProgramDegree(dds);
    }
  }, [selectedProgram]);

  return (
    <div className="p-6" style={{ width: '1100px' }}>
      <div className="fs-1 fw-bold mb-4">New proposed program</div>
      <div className="mb-5">
        <div className="fw-bold">College/school</div>
        {!!userCollege ? (
          <div>{collegeList[userCollege].description}</div>
        ) : (
          <select
            style={{ width: '40%' }}
            value={college ?? ''}
            onChange={(e) => handleCollegeChange(e)}
          >
            <option value="">Select college or school</option>
            {Object.entries(collegeList)
              .filter(
                ([code, obj]) =>
                  code !== 'COE' &&
                  code !== 'CGC' &&
                  code !== 'CLW' &&
                  code !== 'CTPH' &&
                  code !== 'CPROVOST'
              )
              .toSorted(([aCode, aObj], [bCode, bObj]) =>
                aObj.alphaSortDescription.localeCompare(
                  bObj.alphaSortDescription
                )
              )
              .map(([code, obj]) => (
                <option key={code} value={code}>
                  {obj.alphaSortDescription}
                </option>
              ))}
            <option value="OTHER">Other</option>
          </select>
        )}
      </div>
      <div className="mb-5">
        <div className="fw-bold">Department</div>
        {userDepartments.length === 1 ? (
          <div>{departmentList[userDepartments[0]].description}</div>
        ) : (
          <select
            style={{ width: '40%' }}
            value={department ?? ''}
            onChange={(e) => setDepartment(e.target.value)}
            disabled={
              college === 'OTHER' ||
              (!!college &&
                !!collegeList[college] &&
                !collegeList[college].departments)
            }
          >
            <option value="">Select department</option>
            {!!college &&
              college !== 'OTHER' &&
              !!collegeList[college].departments &&
              Object.entries(collegeList[college].departments)
                .filter(([code, obj]) =>
                  !!userDepartments.length
                    ? userDepartments.includes(code)
                    : true
                )
                .toSorted(([aCode, aObj], [bCode, bObj]) =>
                  aObj.description.localeCompare(bObj.description)
                )
                .map(([code, obj]) => (
                  <option key={code} value={code}>
                    {obj.description}
                  </option>
                ))}
          </select>
        )}
      </div>
      <div className="mb-5">
        <div className="fw-bold">Program name</div>
        <input
          type="text"
          placeholder="Enter program name"
          style={{ width: '40%' }}
          value={programName}
          onChange={(e) => setProgramName(e.target.value)}
        />
      </div>
      <div className="mb-5">
        <div className="fw-bold">Program type</div>
        <select
          value={programType ?? ''}
          onChange={(e) => handleSetProgramType(e)}
          style={{ width: '40%' }}
        >
          <option value="">Select program type</option>
          <option value="UGRD">Undergraduate degree</option>
          <option value="MIN">Minor</option>
          <option value="CERT">Certificate</option>
        </select>
      </div>
      <div className="mb-5">
        <div className="fw-bold">Catalog year</div>
        <select
          value={catalogYear ?? ''}
          onChange={(e) => setCatalogYear(e.target.value)}
          style={{ width: '40%' }}
        >
          <option value="">Select catalog year</option>
          {StaticData.yearList.map((year) => (
            <option key={year.value} value={year.value}>
              {year.label}
            </option>
          ))}
        </select>
      </div>
      <form className="uds-form mb-2">
        <div className="fw-bold">Choose a method to set up this checksheet</div>
        <fieldset>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="blankChecksheet"
              data-ga-input="radio button"
              data-ga-input-name="onclick"
              data-ga-input-event="select"
              data-ga-input-action="click"
              data-ga-input-region="main content"
              data-ga-input-section="Create from blank checksheet"
              value="blankChecksheet"
              checked={selectedRadio === 'blankChecksheet'}
              onChange={(e) => handleSelectRadio(e)}
            />
            <label className="form-check-label" htmlFor="blankChecksheet">
              <div>Create from blank checksheet</div>
              <div className="fw-normal text-gray-6">
                Build program requirements from scratch
              </div>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="existingProgram"
              data-ga-input="radio button"
              data-ga-input-name="onclick"
              data-ga-input-event="select"
              data-ga-input-action="click"
              data-ga-input-region="main content"
              data-ga-input-section="Create from existing program"
              value="existingChecksheet"
              checked={selectedRadio === 'existingChecksheet'}
              onChange={(e) => handleSelectRadio(e)}
            />
            <label className="form-check-label" htmlFor="existingProgram">
              <div>Create from existing checksheet</div>
              <div className="fw-normal text-gray-6">
                Start with an existing checksheet and make modifications as
                needed. Recommended only for similar programs with shared
                requirements.
              </div>
            </label>
          </div>
        </fieldset>
      </form>

      {selectedRadio === 'blankChecksheet' && (
        <div className="mb-6">
          <div className="fw-bold">Choose a starter template</div>
          <div className="mb-1">
            Templates are preconfigured with essential requirements and
            validation rules.
          </div>
          <select
            style={{ width: '40%' }}
            value={selectedTemplate ?? ''}
            onChange={(e) => setSelectedTemplate(e.target.value)}
          >
            <option value="">Select template</option>
            {templateList
              .filter((template) =>
                programType
                  ? template.details.programType === programTypes[programType]
                  : true
              )
              // .toSorted((a, b) => sortByProperty(a, b, 'componentName'))
              .map((template) => (
                <option key={template.id} value={template.id}>
                  {template.details.templateName}
                </option>
              ))}
          </select>
        </div>
      )}

      {selectedRadio === 'existingChecksheet' && (
        <div className="mb-9">
          {!selectedChecksheet ? (
            <>
              <div className="fw-bold lh-1 mb-1">Search programs</div>
              <div className="position-relative">
                <input
                  placeholder="Search by program title or plan code"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  style={{ width: '40%' }}
                />
                {searchString && (
                  <div
                    className="border border-gray-5 w-100 overflow-y-auto"
                    style={{ maxHeight: '400px', zIndex: 1 }}
                  >
                    {proposedList
                      .filter(
                        (proposed) =>
                          proposed.checksheetObj.acadplan
                            .toLowerCase()
                            .includes(searchString.toLowerCase()) ||
                          (
                            proposedString +
                            proposed.checksheetObj.checksheetName
                          )
                            .toLowerCase()
                            .includes(searchString.toLowerCase())
                      )
                      .map((proposed, index, array) => (
                        <div key={index} className="bg-white px-2">
                          <div
                            className="border-bottom border-gray-5 py-2"
                            onClick={() =>
                              handleSelectChecksheet(proposed, true)
                            }
                            role="button"
                          >
                            <SearchProposed
                              proposed={proposed}
                              college={
                                proposed.checksheetObj.college !== 'OTHER'
                                  ? collegeList[proposed.checksheetObj.college]
                                  : { description: 'Other' }
                              }
                            />
                          </div>
                        </div>
                      ))}
                    {programList
                      .filter((program) =>
                        programType === 'UGRD'
                          ? program.acadPlanType !== 'MIN' &&
                            program.acadPlanType !== 'CER' &&
                            program.acadPlanType !== 'SAC'
                          : programType === 'MIN'
                            ? program.acadPlanType === 'MIN'
                            : programType === 'CERT'
                              ? program.acadPlanType === 'CER' ||
                                program.acadPlanType === 'SAC'
                              : true
                      )
                      .filter((program) =>
                        checksheetList.some(
                          (checksheet) =>
                            checksheet.id.split('*')[2] ===
                              program.acadPlanCode &&
                            ((!program.subplan &&
                              checksheet.id.split('*')[3] === 'null') ||
                              program.subplan?.acadSubPlanCode ===
                                checksheet.id.split('*')[3])
                        )
                      )
                      .filter(
                        (program) =>
                          program.acadPlanCode
                            .toLowerCase()
                            .includes(searchString.toLowerCase()) ||
                          program.acadPlanDescription
                            .toLowerCase()
                            .includes(searchString.toLowerCase()) ||
                          (program.subplan &&
                            (program.subplan.acadSubPlanCode
                              .toLowerCase()
                              .includes(searchString.toLowerCase()) ||
                              program.subplan.description
                                .toLowerCase()
                                .includes(searchString.toLowerCase())))
                      )
                      .map((program, index, array) => (
                        <div key={index} className="bg-white px-2">
                          <div
                            className={classNames(
                              index !== array.length - 1 && 'border-bottom',
                              'border-gray-5 py-2'
                            )}
                            onClick={() =>
                              handleSelectChecksheet(program, false)
                            }
                            role="button"
                          >
                            <SearchProgram program={program} />
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="bg-gray-1 border border-gray-3 w-100 mb-3">
                <div className="p-2">
                  <div className="d-flex justify-content-between mb-1">
                    <div className="fw-bold" style={{ fontSize: '18px' }}>
                      {selectedProgram.acadPlanDescription}
                      {selectedProgramDegree}
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <div>{selectedProgram.acadPlanCode}</div>
                      <div
                        className="bg-white border border-gray-3 lh-1 rounded-circle"
                        style={{
                          height: 'min-content',
                          padding: '3px 6px 5px',
                        }}
                        onClick={handleClearSelectedChecksheet}
                        role="button"
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          style={{ height: '12px', width: '12px' }}
                        />
                      </div>
                    </div>
                  </div>

                  {selectedProgram.subplan && (
                    <div className="d-flex gap-2 justify-content-between">
                      <div className="fw-bold">
                        {selectedProgram.subplan.description}
                        <span className="subplan-tag ms-1">Subplan</span>
                      </div>
                      <div style={{ margin: '3px 50px 0 0' }}>
                        {selectedProgram.subplan.acadSubPlanCode}
                      </div>
                    </div>
                  )}

                  <div className="d-flex gap-1 align-items-center mb-1">
                    <FontAwesomeIcon
                      icon={faBuildingColumns}
                      className="text-gray-5"
                      width={'16px'}
                    />
                    {!!selectedProgram?.college ? (
                      <div>
                        {selectedProgram.college !== 'OTHER'
                          ? collegeList[selectedProgram.college].description
                          : 'Other'}
                      </div>
                    ) : (
                      !!selectedProgram.owners &&
                      selectedProgram.owners.map((owner, index, array) => (
                        <Fragment key={owner.collegeAcadOrg}>
                          {owner.collegeDescription}
                          {index !== array.length - 1 && ', '}
                        </Fragment>
                      ))
                    )}
                  </div>
                  {!selectedProgram.proposed && (
                    <div className="d-flex gap-1 align-items-center">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="text-gray-5"
                        width={'16px'}
                      />
                      {!!selectedProgram.campusesOffered &&
                        selectedProgram.campusesOffered.map(
                          (campus, index, array) => (
                            <Fragment key={campus.campusCode}>
                              {
                                StaticData.campusUndergradList.find(
                                  (camp) => camp.value === campus.campusCode
                                ).label
                              }
                              {index !== array.length - 1 && ', '}
                            </Fragment>
                          )
                        )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <div className="d-flex justify-content-between align-items-center">
        <Button
          color="dark"
          label="Cancel"
          size="medium"
          onClick={handleClose}
        />
        <Button
          color="gold"
          label="Create checksheet"
          size="medium"
          onClick={handleCreateChecksheet}
          disabled={
            !college ||
            (!department &&
              college !== 'OTHER' &&
              !!college &&
              !!collegeList[college] &&
              collegeList[college].departments) ||
            !programType ||
            !programName ||
            !catalogYear ||
            (!selectedTemplate && !selectedChecksheet)
          }
        />
      </div>
    </div>
  );
};

export default NewProposedProgramForm;
