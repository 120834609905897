import React, { useState } from 'react';
// import { useQuery } from '@tanstack/react-query';
// import { checksheetGetHistory } from 'apis/checksheetAPIs';
import useServiceAuth from 'auth/useServiceAuth';
import { Modal } from 'react-bootstrap';
import {
  faArrowLeft,
  faCircle,
  faClipboardCheck,
  faFlagCheckered,
  faInfoCircle,
  faQuestion,
  faTriangleExclamation,
  faXmark,
  faTrash,
  faThumbsUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from 'utils';
import StaticData from './StaticData';
import { useSelector } from 'react-redux';
import { Button } from '@asu/components-core';
import { useMutation } from '@tanstack/react-query';
import { checksheetRequestRecall } from 'apis/checksheetAPIs';

const ActionLabel = {
  recall: 'Recalled by ',
  recallRequest: 'Recall requested by ',
  approve: 'Recall approved',
  deny: 'Recall denied',
  return: 'Returned to ',
  submit: 'Submitted to ',
  complete: 'Marked as completed',
  changed: 'Status changed',
};

const ActionIcon = {
  recall: faTriangleExclamation,
  approve: faThumbsUp,
  deny: faXmark,
  return: faArrowLeft,
  submit: faClipboardCheck,
  complete: faFlagCheckered,
  changed: faInfoCircle,
};

const ActionColor = {
  recall: { background: 'bg-asu-pink', color: 'text-white' },
  approve: { background: 'bg-white', color: 'text-success-custom' },
  deny: { background: 'bg-danger-custom', color: 'text-danger-custom' },
  return: { background: 'bg-warning-custom', color: 'text-warning-custom' },
  submit: { background: 'bg-success-custom', color: 'text-success-custom' },
  complete: { background: 'bg-asu-green', color: 'text-gray-7' },
  changed: { background: 'bg-info-custom', color: 'text-info-custom' },
};

const statusCircle = {
  DEPARTMENT: { color: 'text-gray-4' },
  COLLEGE: { color: 'text-gray-7' },
  PROVOST: { color: 'text-warning' },
  DARS: { color: 'text-info' },
  COMPLETE: { color: 'text-success' },
};

const HistoryItem = ({
  item,
  user,
  latestRequest,
  handleRequestRecall,
  recalledBy,
  disableRequestActions,
}) => {
  const userIsProvost = user?.role?.includes('PROVOST');

  const date = new Date(item.updatedOn).toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const [requestResponse, setRequestResponse] = useState('');

  return (
    <div
      className={classNames(
        item.action === 'RECALL-RECALL' &&
          userIsProvost &&
          latestRequest === item.updatedOn &&
          !!Object.keys(recalledBy).length
          ? 'recall-requests-notification border-start border-8 border-asu-pink bg-white ps-2 pe-3'
          : 'px-3'
      )}
    >
      <div
        className={classNames(
          (item.action !== 'RECALL-RECALL' ||
            !userIsProvost ||
            latestRequest !== item.updatedOn ||
            !Object.keys(recalledBy).length) &&
            'border-bottom border-gray-4',
          'py-2'
        )}
      >
        <div className="d-flex gap-1">
          <div
            className={classNames(
              ActionColor[item.progressAction.toLowerCase()]?.background ??
                'bg-gray-2',
              item.progressAction === 'approve' &&
                'border border-success-custom',
              'rounded-circle'
            )}
            style={{ width: '32px', height: '32px' }}
          >
            <FontAwesomeIcon
              icon={ActionIcon[item.progressAction.toLowerCase()] ?? faQuestion}
              className={classNames(
                ActionColor[item.progressAction.toLowerCase()]?.color
              )}
              style={{
                width: '16px',
                height: '16px',
                margin: item.progressAction === 'approve' ? '7px' : '8px',
              }}
            />
          </div>
          <div className="flex-grow-1" style={{ paddingTop: '4px' }}>
            <div>
              <div className="fw-bold">
                {ActionLabel[
                  item.progressAction +
                    (item.action === 'RECALL-RECALL' ? 'Request' : '')
                ] ?? '\u2014'}
                {(item.action === 'PROGRESS-MODIFY' ||
                  item.action === 'MODIFY-PROGRESS') &&
                (item.progressAction === 'submit' ||
                  item.progressAction === 'return' ||
                  item.progressAction === 'recall') ? (
                  <span>{StaticData.progressRoles[item.progress]}</span>
                ) : (
                  item.action === 'RECALL-RECALL' &&
                  item.progressAction === 'recall' && (
                    <span>{StaticData.progressRoles[item.role]}</span>
                  )
                )}
              </div>
              <div
                className="d-flex flex-wrap column-gap-1 text-gray-6"
                style={{ fontSize: '14px' }}
              >
                <span>{item.asurite}</span>
                <span className="fw-bold">{'\u00B7'}</span>
                <span className="text-nowrap" style={{ width: 'fit-content' }}>
                  {date}
                </span>
              </div>
            </div>
            {(item.action === 'PROGRESS-MODIFY' ||
              item.action === 'MODIFY-PROGRESS' ||
              item.action === 'RECALL-RECALL' ||
              item.action === 'RECALL-APPROVE' ||
              item.action === 'RECALL-DENY') &&
              item.note &&
              item.note !== 'None' && (
                <div className="mt-2">
                  <span className="fw-bold">Message: </span>
                  {item.note}
                </div>
              )}
            {(item.action === 'PROGRESS-MODIFY' ||
              item.action === 'MODIFY-PROGRESS' ||
              item.action === 'RECALL-RECALL') &&
              item.progressAction === 'changed' && (
                <div className="d-flex gap-1 mt-2">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className={statusCircle[item.after.progress].color}
                    width={'12px'}
                    style={{
                      marginTop: '4px',
                    }}
                  />
                  {StaticData.statusArray[item.after.progress]}
                </div>
              )}
            {item.action === 'RECALL-RECALL' &&
              userIsProvost &&
              latestRequest === item.updatedOn &&
              !!Object.keys(recalledBy).length && (
                <div className="mt-2">
                  <div className="fw-bold">Response (optional)</div>
                  <textarea
                    id="activeRequest"
                    placeholder="Write a message here"
                    rows={5}
                    className="w-100"
                    value={requestResponse}
                    onChange={(e) => setRequestResponse(e.target.value)}
                  />
                  <div className="d-flex gap-1">
                    <Button
                      label="Approve request"
                      color="gold"
                      size="small"
                      onClick={() =>
                        handleRequestRecall(requestResponse, 'approve')
                      }
                      disabled={disableRequestActions}
                    />
                    <Button
                      label="Deny"
                      color="dark"
                      size="small"
                      onClick={() =>
                        handleRequestRecall(requestResponse, 'deny')
                      }
                      disabled={disableRequestActions}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChecksheetHistory = ({ checksheetId, handleRefetchChecksheet, tab }) => {
  const { getAccessToken } = useServiceAuth();
  const [showCourseListModal, setShowCourseListModal] = useState(false);
  // const [history, setHistory] = useState([]);
  const recalledBy = useSelector((state) => state.checksheet.recalledBy);
  const history = useSelector((state) => state.history.updatedHistory);
  const [showItems, setShowItems] = useState('all');
  const [disableRequestActions, setDisableRequestActions] = useState(false);

  const latestRequest = history?.length
    ? history.reduce((latest, item) => {
        if (!latest) return item.updatedOn;
        return new Date(item.updatedOn) > new Date(latest) &&
          item.action === 'RECALL-RECALL'
          ? item.updatedOn
          : latest;
      }, null)
    : null;

  const user = useSelector((state) => state.user);

  // const [historyCourseLists, setHistoryCourseLists] = useState({});

  const historyDeletedCourseLists = useSelector(
    (state) => state.history.deletedCourseLists
  );

  // const checksheetState = useSelector((state) => state.checksheet);

  // const {
  //   data: historyData,
  //   isSuccess: historySuccess,
  //   refetch: refetchHistory,
  // } = useQuery({
  //   queryKey: ['checksheetHistory', checksheetId],
  //   queryFn: async () => {
  //     const token = await getAccessToken();
  //     return checksheetGetHistory({ id: checksheetId, token });
  //   },
  //   enabled: !!checksheetId,
  // });

  const {
    mutate: requestChecksheetRecall,
    // error: updateChecksheetProgressError,
  } = useMutation({
    mutationFn: checksheetRequestRecall,
    onSuccess: () => {
      setTimeout(() => {
        handleRefetchChecksheet();
        setDisableRequestActions(() => false);
      }, 1000);
    },
    onError: (error) => {
      setDisableRequestActions(() => false);
    },
  });

  const handleRequestRecall = async (message, action) => {
    setDisableRequestActions(true);
    const jsonData = {
      action,
      note: message,
    };
    const token = await getAccessToken();

    await requestChecksheetRecall({
      id: checksheetId,
      jsonData,
      token,
    });
  };

  const showReviews = (item) => {
    return !!(
      (item.action === 'PROGRESS-MODIFY' ||
        item.action === 'MODIFY-PROGRESS') &&
      (item.progressAction === 'submit' ||
        item.progressAction === 'return' ||
        item.progressAction === 'complete')
    );
  };

  const showMessages = (item) => {
    return !!(item.note && item.note !== 'None');
  };

  const showRecalls = (item) => {
    return !!(
      item.action === 'RECALL-RECALL' ||
      ((item.action === 'PROGRESS-MODIFY' ||
        item.action === 'MODIFY-PROGRESS') &&
        item.progressAction === 'recall')
    );
  };

  const sortByDate = (a, b) => {
    const dateA = new Date(a.updatedOn);
    const dateB = new Date(b.updatedOn);
    return dateB - dateA;
  };

  const handleCloseCourseListModal = () => {
    setShowCourseListModal(false);
  };
  const handleOpenCourseListModal = () => {
    setShowCourseListModal(true);
  };

  // useEffect(() => {
  //   if (historySuccess) {
  //     console.log(historyData);
  //     const updatedHistory = [];

  //     historyData.forEach((item) => {
  //       if (
  //         item.action.S === 'PROGRESS-MODIFY' ||
  //         item.action.S === 'MODIFY-PROGRESS'
  //       ) {
  //         const jsonDescription = JSON.parse(item.description.S);

  //         const updatedItem = {
  //           action: item.action.S,
  //           updatedOn: item.updatedOn.S,
  //           progress: jsonDescription.progress,
  //           progressAction: jsonDescription.action,
  //           asurite: jsonDescription.asurite,
  //           note: jsonDescription.note,
  //           before: JSON.parse(item.before.S),
  //           after: JSON.parse(item.after.S),
  //         };

  //         updatedHistory.push(updatedItem);
  //       }
  //     });

  //     console.log(updatedHistory);

  //     setHistory([...updatedHistory]);
  //   }
  // }, [historyData, historySuccess]);

  // useEffect(() => {
  //   refetchHistory();
  // }, [checksheetState, refetchHistory]);

  return (
    <>
      {tab === 'history' ? (
        <div className="d-flex flex-column gap-1 mt-1 overflow-y-hidden">
          <div className="d-flex gap-2 justify-content-between align-items-center px-3">
            <h4>History</h4>
            <div className="d-flex gap-1 align-items-center">
              <div>Show: </div>
              <select
                style={{ width: '167px' }}
                onChange={(e) => setShowItems(e.target.value)}
              >
                <option value="all">All</option>
                {/* <option value="edits">Checksheet edits</option> */}
                <option value="reviews">Submissions and reviews</option>
                <option value="messages">Message included</option>
                <option value="recalls">Recalls</option>
              </select>
            </div>
          </div>
          <div
            className="scrollbox"
            // style={{ scrollbarGutter: 'stable both-edges' }}
          >
            <div className="scrollbox-content pb-2">
              {history
                .filter((item) =>
                  showItems === 'all'
                    ? true
                    : showItems === 'reviews'
                      ? showReviews(item)
                      : showItems === 'messages'
                        ? showMessages(item)
                        : showItems === 'recalls'
                          ? showRecalls(item)
                          : true
                )
                .toSorted(sortByDate)
                .map((item, index) => (
                  <HistoryItem
                    key={index}
                    item={item}
                    user={user}
                    latestRequest={latestRequest}
                    handleRequestRecall={handleRequestRecall}
                    recalledBy={recalledBy}
                    disableRequestActions={disableRequestActions}
                  />
                ))}
            </div>
          </div>
        </div>
      ) : tab === 'courseList' ? (
        <div className="d-flex flex-column gap-1 mt-10 overflow-y-hidden">
          {historyDeletedCourseLists &&
            Object.keys(historyDeletedCourseLists).length > 0 && (
              <div
                className="d-flex gap-1 align-items-center bg-gray-2 py-1 px-4 rounded-4 mx-2"
                style={{ cursor: 'pointer', width: 'fit-content' }}
                onClick={handleOpenCourseListModal}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  title="Delete course list"
                  className="deleteButton"
                  style={{ width: '18px', marginRight: '8px' }} // Adjust icon size and spacing
                />
                <span>
                  Deleted Lists ({Object.keys(historyDeletedCourseLists).length}
                  )
                </span>
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-success"
                  style={{ width: '10px', paddingTop: '4px' }}
                />
              </div>
            )}

          {/* Modal for deleted course lists */}
          <Modal show={showCourseListModal} onHide={handleCloseCourseListModal}>
            <div style={{ width: '800px' }} className="p-6">
              <div
                className="border border-gray-3 rounded-circle lh-1"
                style={{ padding: '7px 10px', float: 'right' }}
                onClick={handleCloseCourseListModal}
                role="button"
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  width={'10px'}
                  height={'10px'}
                  className="text-gray-7"
                />
              </div>
              {/* Loop through each deleted course list */}
              {historyDeletedCourseLists && (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead style={{ whiteSpace: 'nowrap' }}>
                      <tr>
                        <th>Course list name</th>
                        <th>Updated By</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(historyDeletedCourseLists).map(
                        ([key, items]) =>
                          items.map((item, index) => (
                            <tr key={`${key}-${index}`}>
                              <td>{key}</td>
                              <td>{item.updatedBy}</td>
                              <td>
                                {new Date(item.updatedOn).toLocaleDateString(
                                  'en-us',
                                  {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  }
                                )}
                              </td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </Modal>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ChecksheetHistory;
