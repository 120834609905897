import axios from 'axios';

export async function userSave({ jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/user/save`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .put(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function userGetAll({ token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/user/get-all`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = [];

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}

export async function userGet({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/user/get/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}

export async function userDelete({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/user/delete/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return await axios.delete(api, headers).then((response) => {
    console.log(response.data);
  });
}

export async function settingGet({ setting, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/settings/get/${setting}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}

export async function settingSave({ jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/settings/save`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .put(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function subplanActivationGetAll({ year, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/settings/subplan/${year}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}

export async function subplanActivation({
  year,
  plan,
  subplan,
  active,
  token,
}) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/settings/subplan/${year}/${plan}/${subplan}/${active}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .put(api, null, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function submitPlanExtensions({ jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/planextension`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .put(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function getPlanExtensionsProgress({ year, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/admin/planextension/progress/${year}`;
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = [];
  await axios.get(api, headers).then((response) => {
    // console.log(response.data);
    res = response.data;
  });

  return res;
}
