const StaticData = {
  reqList: [
    { value: 'req_course', label: 'Course' },
    { value: 'req_course_list', label: 'Course List' },
    { value: 'req_elective', label: 'Elective' },
    { value: 'req_gs', label: 'General Studies' },
    { value: 'req_text', label: 'Text' },
    { value: 'req_check', label: 'Check' },
  ],
  templateReqList: [
    { value: 'req_course', label: 'Course' },
    { value: 'req_gs', label: 'General Studies' },
    { value: 'req_elective', label: 'Elective' },
    { value: 'req_text', label: 'Text' },
    { value: 'req_check', label: 'Check' },
  ],
  courseListReqList: [
    { value: 'req_course', label: 'Course' },
    { value: 'req_gs', label: 'General Studies' },
    { value: 'req_elective', label: 'Elective' },
    { value: 'req_text', label: 'Text' },
  ],
  programTypeList: [
    { value: 'undergrad', label: 'Undergraduate degree' },
    { value: 'minor', label: 'Minor' },
    { value: 'cert', label: 'Certificate' },
  ],
  electiveType: [
    { value: 'general', label: 'University' },
    { value: 'subject', label: 'Subject' },
  ],
  checkType: [
    { value: 'gpa', label: 'GPA Check' },
    { value: 'milestone', label: 'Milestone' },
  ],
  checkSubtype: [
    { value: 'asu_cum_gpa', label: 'ASU Cum GPA' },
    { value: 'major_gpa', label: 'Major GPA' },
    { value: 'subject', label: 'Subject' },
    { value: 'courses', label: 'Courses' },
  ],
  ruleTypeList: [
    { value: 'gs', label: 'General Studies' },
    { value: 'necessary', label: 'Necessary Course' },
    { value: 'critical', label: 'Critical Course' },
  ],
  connectors: [
    { value: '', label: '' },
    { value: 'AND', label: 'AND' },
    { value: 'OR', label: 'OR' },
    { value: 'or', label: 'or' },
  ],
  gsList: [
    { value: 'AMIT', label: 'American Institutions' },
    { value: 'GCSI', label: 'Global Communities, Societies, and Individuals' },
    { value: 'CIVI', label: 'Governance and Civic Engagement' },
    { value: 'HUAD', label: 'Humanities, Arts and Design' },
    { value: 'MATH', label: 'Mathematics' },
    { value: 'QTRS', label: 'Quantitative Reasoning' },
    { value: 'SCIT', label: 'Scientific Thinking in Natural Sciences' },
    { value: 'SOBE', label: 'Social and Behavioral Sciences' },
    { value: 'SUST', label: 'Sustainability' },
  ],
  gpaList: [
    { value: 0, label: 'Clear selection' },
    { value: 4.0, label: '4.00 (A)' },
    { value: 3.67, label: '3.67 (A-)' },
    { value: 3.33, label: '3.33 (B+)' },
    { value: 3.0, label: '3.00 (B)' },
    { value: 2.67, label: '2.67 (B-)' },
    { value: 2.33, label: '2.33 (C+)' },
    { value: 2.0, label: '2.00 (C)' },
  ],
  gradeList: [
    { value: ' ', label: 'Clear selection' },
    { value: 'A', label: 'A 4.00' },
    { value: 'A-', label: 'A- 3.67' },
    { value: 'B+', label: 'B+ 3.33' },
    { value: 'B', label: 'B 3.00' },
    { value: 'B-', label: 'B- 2.67' },
    { value: 'C+', label: 'C+ 2.33' },
    { value: 'C', label: 'C 2.00' },
    { value: 'Y', label: 'Y' },
  ],
  divisionList: [
    { value: ' ', label: 'Clear selection' },
    { value: '100', label: '100-Level' },
    { value: '200', label: '200-Level' },
    { value: '300', label: '300-Level' },
    { value: '400', label: '400-Level' },
    { value: 'Lower', label: 'Lower division' },
    { value: 'Upper', label: 'Upper division' },
  ],
  yearList: [
    ...(process.env.REACT_APP_SERVICEAUTH_OAUTH_PUBLIC_CLIENT_ID.split(
      '-'
    ).includes('nonprod')
      ? [
          { value: '2023', label: '2023-2024' },
          { value: '2024', label: '2024-2025' },
        ]
      : []),
    { value: '2025', label: '2025-2026' },
    { value: '2026', label: '2026-2027' },
    { value: '2027', label: '2027-2028' },
    { value: '2028', label: '2028-2029' },
  ],
  campusGraduateList: [
    { value: 'DTPHX', label: 'Downtown Phoenix' },
    { value: 'POLY', label: 'Polytechnic' },
    { value: 'TEMPE', label: 'Tempe' },
    { value: 'WEST', label: 'West Valley' },
    { value: 'SYNC', label: 'ASU Sync' },
    { value: 'HAINA', label: 'ASU at Hainan' },
    { value: 'LOSAN', label: 'ASU at Los Angeles' },
    { value: 'MESA', label: 'ASU at Mesa City Center' },
    { value: 'MXCTY', label: 'ASU at Mexico City' },
    { value: 'TUCSN', label: 'ASU at Tucson' },
    { value: 'WASHD', label: 'ASU at Washington, D.C.' },
    { value: 'AWC', label: 'ASU at Yuma' },
    { value: 'ONLNE', label: 'Online, ASU Local' },
  ],
  campusUndergradList: [
    { value: 'DTPHX', label: 'Downtown Phoenix' },
    { value: 'POLY', label: 'Polytechnic' },
    { value: 'TEMPE', label: 'Tempe' },
    { value: 'WEST', label: 'West Valley' },
    { value: 'SYNC', label: 'ASU Sync' },
    { value: 'COCHS', label: 'ASU at Cochise' },
    { value: 'CALHC', label: 'ASU at Lake Havasu' },
    { value: 'LOSAN', label: 'ASU at Los Angeles' },
    { value: 'NEAZ', label: 'ASU at Northeastern Arizona' },
    { value: 'PIMA', label: 'ASU at Pima' },
    { value: 'CAC', label: 'ASU at Pinal' },
    { value: 'EAC', label: 'ASU at The Gila Valley' },
    { value: 'TUCSN', label: 'ASU at Tucson' },
    { value: 'YAVAP', label: 'ASU at Yavapai' },
    { value: 'AWC', label: 'ASU at Yuma' },
    { value: 'ONLNE', label: 'Online, ASU Local' },
  ],
  statusList: [
    { value: 'DEPARTMENT', label: 'Pending department submission' },
    { value: 'COLLEGE', label: 'Pending college/school submission' },
    { value: 'PROVOST', label: 'Pending Provost review' },
    { value: 'DARS', label: 'Pending DARS encoding' },
    { value: 'COMPLETE', label: 'Completed' },
  ],
  statusArray: {
    DEPARTMENT: 'Pending department submission',
    COLLEGE: 'Pending college/school submission',
    PROVOST: 'Pending Provost review',
    DARS: 'Pending DARS encoding',
    COMPLETE: 'Completed',
  },
  userRoles: [
    { value: 'DEPARTMENT', label: 'Department' },
    { value: 'COLLEGE', label: 'College' },
    { value: 'PROVOST', label: 'Provost' },
    { value: 'DARS', label: 'DARS' },
    { value: 'VIEWER', label: 'Viewer' },
  ],
  progressRoles: {
    DEPARTMENT: 'department',
    COLLEGE: 'college/school',
    PROVOST: 'Provost',
    DARS: 'DARS',
    COMPLETE: 'Complete',
  },
  progressReview: {
    DEPARTMENT: { previousReview: '', nextReview: 'COLLEGE' },
    COLLEGE: { previousReview: 'DEPARTMENT', nextReview: 'PROVOST' },
    PROVOST: { previousReview: 'COLLEGE', nextReview: 'DARS' },
    DARS: { previousReview: 'PROVOST', nextReview: 'COMPLETE' },
    COMPLETE: { previousReview: 'DARS', nextReview: '' },
  },
};

export default StaticData;
