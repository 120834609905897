import React from 'react';
import { useSelector } from 'react-redux';
import StaticData from './StaticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  // faGripVertical,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setCourses, setCourseListId } from 'state/slices/courseListSlice';
import Course from './RequirementCourse';
import Text from './RequirementText';
import GeneralStudy from './RequirementGeneralStudy';
import Elective from './RequirementElective';
import Check from './RequirementCheck';

//pass the createWrappedData function to the child, pass dataFromParent to child, call back the dataToParent to parent
// modal=true, display modal, otherwise display as text
const CourseList = ({
  index,
  data,
  handleInputChange,
  handleDeleteComponent,
  handleShowAsCourseList,
}) => {
  const dispatch = useDispatch();

  const courseLists = useSelector((state) => state.checksheet.courseLists);

  const handleConnectorChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'connector', value);
  };

  const handleRequirementTypeChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'courseListId', '');
    handleInputChange(index, 'division', '');
    handleInputChange(index, 'itemType', value);
  };

  const handleEditCourseList = () => {
    dispatch(setCourseListId(data.courseListId));
    dispatch(setCourses(courseLists[data.courseListId].courses));
    handleShowAsCourseList(false);
  };

  const handleCourseListIdChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'courseListId', value);
  };

  const handleDivisionChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'division', value);
  };

  return (
    <>
      {data.itemType === 'req_course_list' || !data.itemType ? (
        <div className="d-flex gap-2 pb-2">
          {/* Remove until reordering is finished */}
          {/* <FontAwesomeIcon
            icon={faGripVertical}
            style={{ width: '15px' }}
            className="pt-4"
          /> */}
          <div className="mt-4 me-1">
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => handleDeleteComponent(index)}
              className="deleteButton fa-trash-active}"
            />
          </div>

          <div style={{ width: '132px' }}>
            <div className="fw-bold text-nowrap">Logical operator</div>
            <div>
              <select
                className="text-gray-7 p-1 w-100"
                value={data.connector ?? ''}
                name="connector"
                onChange={(e) => handleConnectorChange(e)}
                disabled={
                  index === 0 &&
                  (!data.connector || data.connector.trim() === '')
                }
              >
                {StaticData.connectors.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <div className="fw-bold">Requirement type</div>
            <div>
              <select
                style={{
                  width: '150px',
                }}
                className="text-gray-7 p-1"
                value={'req_course_list'}
                onChange={(e) => handleRequirementTypeChange(e)}
              >
                {StaticData.reqList.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <div className="d-flex justify-content-between">
              <div className="fw-bold">List</div>

              <div className="d-flex gap-2">
                {data.courseListId && (
                  <div
                    className="button-link "
                    onClick={() => handleEditCourseList()}
                    role="button"
                  >
                    Edit list
                  </div>
                )}
                <div
                  className="button-link "
                  onClick={() => handleShowAsCourseList(true)}
                  role="button"
                >
                  Create new list
                </div>
              </div>
            </div>
            <div>
              <select
                style={{
                  width: '400px',
                }}
                className="text-gray-7 p-1"
                value={data.courseListId ?? ''}
                onChange={(e) => handleCourseListIdChange(e)}
              >
                <option value="" disabled>
                  Choose Course List
                </option>
                {courseLists &&
                  Object.keys(courseLists).map((key) => (
                    <option key={key} value={key}>
                      {courseLists[key]?.courseListName || key}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div>
            <div className="fw-bold">Level</div>
            <select
              style={{
                width: '178px',
              }}
              className="p-1"
              value={data.division ?? ''}
              onChange={(e) => handleDivisionChange(e)}
            >
              <option value="" disabled>
                Level
              </option>
              {StaticData.divisionList.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : data.itemType === 'req_course' ? (
        <Course
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={true}
        />
      ) : data.itemType === 'req_elective' ? (
        <Elective
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={true}
        />
      ) : data.itemType === 'req_gs' ? (
        <GeneralStudy
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={true}
        />
      ) : data.itemType === 'req_text' ? (
        <Text
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={true}
        />
      ) : data.itemType === 'req_check' ? (
        <Check
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={true}
        />
      ) : (
        <div>{/* Handle other cases if needed */}</div>
      )}
    </>
  );
};

export default CourseList;
