import { createSlice } from '@reduxjs/toolkit';

export const dataListsSlice = createSlice({
  name: 'dataLists',
  initialState: {
    componentList: [],
    isComponentListLoading: false,
    folderList: [],
    templateList: [],
    isTemplateListLoading: false,
    checksheetList: [],
    isChecksheetListLoading: false,
    proposedChecksheetList: [],
    isProposedChecksheetListLoading: false,
    undergradDegreeList: [],
    isUndegradDegreeListLoading: false,
    minorList: [],
    isMinorListLoading: false,
    certificateList: [],
    isCertificateListLoading: false,
    allProgramsList: [],
    isAllProgramsListLoading: false,
    textRequirementList: [],
    isTextRequirementListLoading: false,
    collegeList: [],
    isCollegeListLoading: false,
    departmentList: [],
    isDepartmentListLoading: false,
  },
  reducers: {
    setComponentList: (state, action) => {
      state.componentList = action.payload;
    },
    setComponentListLoading: (state, action) => {
      state.isComponentListLoading = action.payload;
    },
    setFolderList: (state, action) => {
      state.folderList = action.payload;
    },
    setTemplateList: (state, action) => {
      state.templateList = action.payload;
    },
    setTemplateListLoading: (state, action) => {
      state.isTemplateListLoading = action.payload;
    },
    setChecksheetList: (state, action) => {
      state.checksheetList = action.payload;
    },
    setChecksheetListLoading: (state, action) => {
      state.isChecksheetListLoading = action.payload;
    },
    setProposedChecksheetList: (state, action) => {
      state.proposedChecksheetList = action.payload;
    },
    setProposedChecksheetListLoading: (state, action) => {
      state.isProposedChecksheetListLoading = action.payload;
    },
    setUndergradDegreeList: (state, action) => {
      state.undergradDegreeList = action.payload;
    },
    setUndergradDegreeListLoading: (state, action) => {
      state.isUndegradDegreeListLoading = action.payload;
    },
    setMinorList: (state, action) => {
      state.minorList = action.payload;
    },
    setMinorListLoading: (state, action) => {
      state.isMinorListLoading = action.payload;
    },
    setCertificateList: (state, action) => {
      state.certificateList = action.payload;
    },
    setCertificateListLoading: (state, action) => {
      state.isCertificateListLoading = action.payload;
    },
    setAllProgramsList: (state, action) => {
      state.allProgramsList = action.payload;
    },
    setAllProgramsListLoading: (state, action) => {
      state.isAllProgramsListLoading = action.payload;
    },
    setTextRequirementList: (state, action) => {
      state.textRequirementList = action.payload;
    },
    setTextRequirementListLoading: (state, action) => {
      state.isTextRequirementListLoading = action.payload;
    },
    setCollegeList: (state, action) => {
      state.collegeList = action.payload;
    },
    setCollegeListLoading: (state, action) => {
      state.isCollegeListLoading = action.payload;
    },
    setDepartmentList: (state, action) => {
      state.departmentList = action.payload;
    },
    setDepartmentListLoading: (state, action) => {
      state.isDepartmentListLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setComponentList,
  setComponentListLoading,
  setFolderList,
  setTemplateList,
  setTemplateListLoading,
  setChecksheetList,
  setChecksheetListLoading,
  setProposedChecksheetList,
  setProposedChecksheetListLoading,
  setUndergradDegreeList,
  setUndergradDegreeListLoading,
  setMinorList,
  setMinorListLoading,
  setCertificateList,
  setCertificateListLoading,
  setAllProgramsList,
  setAllProgramsListLoading,
  setTextRequirementList,
  isTextRequirementListLoading,
  setCollegeList,
  setCollegeListLoading,
  setDepartmentList,
  setDepartmentListLoading,
} = dataListsSlice.actions;

export default dataListsSlice.reducer;
