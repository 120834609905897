import React, { useEffect, useState } from 'react';
import { Accordion } from '@asu/components-core';
import { fetchDplColleges, fetchDplUndergradPrograms } from 'apis/dplAPIs';
import { useQuery } from '@tanstack/react-query';
import { subplanActivationGetAll, subplanActivation } from 'apis/adminAPIs';
import useServiceAuth from 'auth/useServiceAuth';
import { setYear } from 'state/slices/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import StaticData from '../components/StaticData';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faSearch } from '@fortawesome/free-solid-svg-icons';

// import axios from 'axios';

const SubplanActivation = () => {
  const [error, setError] = useState('');
  const [colleges, setColleges] = useState([]);
  // const [programs, setPrograms] = useState([]);
  const [subplans, setSubplans] = useState([]);
  const [subplanActivationList, setSubplanActivationList] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [accordionKey, setAccordionKey] = useState(0);
  const { isAuthenticated, getAccessToken } = useServiceAuth();
  const year = useSelector((state) => state.settings.year);
  const dispatch = useDispatch();

  // Updating the key of a compopent will issue a re-render of that component.
  // This is needed because the 'openedCard' attribute does not automatically re-render when updated.
  const handleSetSearchString = (e) => {
    setSearchString(e.target.value);
    setAccordionKey(accordionKey === 0 ? 1 : 0);
  };

  const {
    data: fetchCollegesData,
    error: fetchCollegesError,
    isError: fetchCollegesIsError,
    isSuccess: fetchCollegesIsSuccess,
    isPending: fetchCollegesIsPending,
    // fetchStatus: fetchCollegesFetchStatus,
  } = useQuery({
    queryKey: ['colleges'],
    queryFn: fetchDplColleges,
  });

  const {
    data: fetchProgramsData,
    error: fetchProgramsError,
    isError: fetchProgramsIsError,
    isSuccess: fetchProgramsIsSuccess,
    isPending: fetchProgramsIsPending,
    // fetchStatus: fetchProgramsFetchStatus,
  } = useQuery({
    queryKey: ['programs'],
    queryFn: fetchDplUndergradPrograms,
  });

  const {
    data: fetchSubplanActivationListData,
    error: fetchSubplanActivationListError,
    isError: fetchSubplanActivationListIsError,
    isSuccess: fetchSubplanActivationListIsSuccess,
    isPending: fetchSubplanActivationListIsPending,
    // fetchStatus: fetchSubplanActivationListFetchStatus,
  } = useQuery({
    queryKey: ['subplanActivationList', year],
    queryFn: async () => {
      const token = await getAccessToken();
      return subplanActivationGetAll({ year: year, token });
    },
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (fetchCollegesError) setError(fetchCollegesError.message);
    if (fetchCollegesIsSuccess) {
      const colleges = fetchCollegesData;
      setColleges(sortByAlphaSortDescription(colleges));
    }
  }, [fetchCollegesData, fetchCollegesError, fetchCollegesIsSuccess, year]);

  useEffect(() => {
    if (fetchProgramsError) setError(fetchProgramsError.message);
    if (fetchProgramsIsSuccess) {
      const programs = fetchProgramsData;

      if (fetchSubplanActivationListIsError)
        setError(fetchSubplanActivationListError.message);
      if (fetchSubplanActivationListIsSuccess)
        setSubplanActivationList(fetchSubplanActivationListData);

      setSubplans(mapByCollegeAcadOrg(programs, subplanActivationList));
    }
  }, [
    fetchProgramsData,
    fetchProgramsError,
    fetchProgramsIsSuccess,
    subplanActivationList,
    fetchSubplanActivationListData,
    fetchSubplanActivationListError,
    fetchSubplanActivationListIsError,
    fetchSubplanActivationListIsSuccess,
    year,
  ]);

  useEffect(() => {
    const checkboxes = document.querySelectorAll('.form-check-input');
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', handleUpdateSubplan);
    });

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      checkboxes.forEach((checkbox) => {
        checkbox.removeEventListener('change', handleUpdateSubplan);
      });
    };
  }, [subplans, year, searchString]);

  const handleSetYear = (e) => {
    dispatch(setYear(e.target.value));
  };

  // if (
  //   (fetchCollegesIsPending && fetchCollegesFetchStatus === 'fetching') ||
  //   (fetchProgramsIsPending && fetchProgramsFetchStatus === 'fetching')
  // )
  //   return <div className="container">Loading...</div>;

  if (
    fetchCollegesIsError ||
    fetchProgramsIsError ||
    fetchSubplanActivationListIsError
  )
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <div className="container mb-5 mt-5">
      <h3>Subplans</h3>

      <p className="mb-3">
        Select a college/school to view subplans. Turn on a subplan to allow a
        checksheet to be created for that subplan.
      </p>
      <div className="d-flex gap-2 justify-content-end">
        <div>
          <div className="d-flex gap-1 align-items-center">
            <FontAwesomeIcon icon={faSearch} />
            <div className="fw-bold">Search</div>
          </div>
          <input
            type="text"
            value={searchString}
            onChange={(e) => handleSetSearchString(e)}
            placeholder="Search programs or subplans"
            style={{ width: '300px' }}
          />
        </div>
        <div>
          <div className="d-flex gap-1 align-items-center">
            <FontAwesomeIcon icon={faCalendar} />
            <div className="fw-bold">Catalog year</div>
          </div>
          <select
            value={year}
            onChange={(e) => handleSetYear(e)}
            style={{ width: '150px' }}
          >
            {StaticData.yearList.map((year) => (
              <option key={year.value} value={year.value}>
                {year.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      {fetchCollegesIsPending ||
      fetchProgramsIsPending ||
      fetchSubplanActivationListIsPending ? (
        <div>
          {[...Array(14)].map((item, index) => (
            <div key={index} className="mt-2">
              <LoadingSkeleton width={'100%'} height={'59px'} />
            </div>
          ))}
        </div>
      ) : (
        <div className="custom-accordion">
          <Accordions
            colleges={colleges}
            subplans={subplans}
            year={year}
            searchString={searchString}
            accordionKey={accordionKey}
          />
        </div>
      )}
    </div>
  );
};

export default SubplanActivation;

const Accordions = ({
  colleges,
  subplans,
  year,
  searchString,
  accordionKey,
}) => {
  const collegeData = colleges.map((college) =>
    getTableData(college, subplans, year, searchString)
  );

  return (
    <>
      {colleges.map((college, index) => (
        <Accordion
          key={`${index}-${accordionKey}`}
          cards={collegeData[index]}
          openedCard={searchString ? 1 : undefined}
        />
      ))}
      {collegeData.every((data) => data.length === 0) && (
        <div className="fst-italic mt-4">No subplans found</div>
      )}
    </>
  );
};

const getTableData = (college, subplanList, year, searchString) => {
  const tableData = [];
  const body = getBody(subplanList, college.acadOrgCode, year, searchString);

  if (body !== '') {
    const collegeData = {
      content: {
        body,
        header: college.alphaSortDescription,
      },
    };

    tableData.push(collegeData);
  }
  return tableData;
};

const getBody = (subplanList, thisCollege, year, searchString) => {
  let body = '';
  if (subplanList && subplanList !== null && subplanList.size > 0) {
    let subplansByCollege = subplanList.get(thisCollege);
    if (subplansByCollege) {
      subplansByCollege.forEach((plan) => {
        let addPlan = false;
        let planBody = `<div class="row pt-5 pb-2">
            <div class="col-md-6 fw-bold"> ${plan.acadPlanDescription}</div>
            <div class="col-md-6"> ${plan.acadPlanCode}</div>
          </div>`;

        if (
          plan.acadPlanDescription
            .toLowerCase()
            .includes(searchString.toLowerCase()) ||
          plan.acadPlanCode.toLowerCase().includes(searchString.toLowerCase())
        )
          addPlan = true;

        for (let i = 0; i < plan.subplans.length; i++) {
          let checked = plan.subplans[i].isActive ? 'checked' : '';
          let label = plan.subplans[i].isActive ? 'Active' : 'Inactive';

          if (
            !searchString ||
            plan.subplans[i].acadSubPlanCode
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            plan.subplans[i].description
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            plan.acadPlanDescription
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            plan.acadPlanCode.toLowerCase().includes(searchString.toLowerCase())
          ) {
            addPlan = true;
            planBody += `<div class="row">
            <div class="col-md-6"> ${plan.subplans[i].description}</div>
            <div class="col-md-4"> ${plan.subplans[i].acadSubPlanCode}</div>
            <div class="col-md-2">
              <div class="form-check form-switch">
                <input class="form-check-input toggle-switch" type="checkbox" id="switchControl${plan.acadPlanCode}-${plan.subplans[i].acadSubPlanCode}" ${checked} 
                  value="${plan.acadPlanCode} ${plan.subplans[i].acadSubPlanCode} ${year}"
                  data-ga-input="checkbox"
                  data-ga-input-name="onclick"
                  data-ga-input-event="select"
                  data-ga-input-action="click"
                  data-ga-input-region="main content"
                  data-ga-input-section="${plan.subplans[i].acadSubPlanCode}">
                <label class="form-check-label" for="switchControl${plan.acadPlanCode}-${plan.subplans[i].acadSubPlanCode}" >${label}</label>
              </div>
            </div>
          </div>`;
          }
        }

        if (addPlan) body += planBody;
      });
    }
  }

  return body;
};

const mapByCollegeAcadOrg = (programs, subplanActivationList) => {
  const result = new Map();

  if (!!programs.length) {
    programs.forEach((obj) => {
      if (obj.owners) {
        const collegeAcadOrg = obj.owners[0].collegeAcadOrg;
        const hasValidSubplans = obj.subplans && obj.subplans.length > 0;

        if (hasValidSubplans) {
          for (let i = 0; i < obj.subplans.length; i++) {
            const subplan = obj.subplans[i];
            subplan.isActive = false;
            if (subplanActivationList && subplanActivationList !== null) {
              if (
                subplanActivationList[obj.acadPlanCode] &&
                subplanActivationList[obj.acadPlanCode][subplan.acadSubPlanCode]
              ) {
                subplan.isActive =
                  subplanActivationList[obj.acadPlanCode][
                    subplan.acadSubPlanCode
                  ] === true;
              }
            }
          }

          if (!result.has(collegeAcadOrg)) {
            result.set(collegeAcadOrg, []);
          }

          result.get(collegeAcadOrg).push(obj);
        }
      } else console.log('No owners for ', obj.acadPlanCode);
    });
  }
  //iterate over the results map and print in console all the keys
  result.forEach((value, key) => {
    //iterate over the value array and for each object o, sort the property o.subplans which is also an array, by subplans[i].description
    value.forEach((o) => {
      o.subplans.sort((a, b) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      });
    });
  });

  return result;
};

const handleUpdateSubplan = async (e) => {
  const value = e.target.value;
  const [plan, subplan, year] = value.split(' ');
  const isChecked = e.target.checked;

  const token = sessionStorage.getItem('pat.jwt.token');
  let response = await subplanActivation({
    year,
    plan,
    subplan,
    active: isChecked,
    token,
  });
  if (!response || response !== 'SETTINGS-SUBPLANACTIVATION') {
    alert('Error updating subplan ', plan, subplan);
  }

  const label = e.target.checked ? 'Active' : 'Inactive';
  const labelElement = document.querySelector(`label[for="${e.target.id}"]`);
  labelElement.textContent = label;
};

function sortByAlphaSortDescription(arr) {
  return arr.sort((a, b) => {
    if (a.alphaSortDescription < b.alphaSortDescription) {
      return -1;
    }
    if (a.alphaSortDescription > b.alphaSortDescription) {
      return 1;
    }
    return 0;
  });
}
