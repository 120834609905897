import React, { useState } from 'react';
import useServiceAuth from 'auth/useServiceAuth';
// import { fetchDplColleges } from 'apis/dplAPIs';
import { useMutation, useQuery } from '@tanstack/react-query';
import StaticData from '../components/StaticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { userDelete, userGetAll, userSave } from 'apis/adminAPIs';
import { useSelector } from 'react-redux';

const Users = () => {
  const { getAccessToken } = useServiceAuth();

  const [asurite, setAsurite] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedCollege, setSelectedCollege] = useState('');
  const [selectedDarsColleges, setDarsSelectedColleges] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [showColleges, setShowColleges] = useState(false);
  const [showDepartments, setShowDepartments] = useState(false);
  const [numberOfDepartments, setNumberOfDepartments] = useState(1);
  const [numberOfDarsColleges, setNumberOfDarsColleges] = useState(1);
  const [lockAddUser, setLockAddUser] = useState(false);

  const collegeList = useSelector((state) => state.dataLists.collegeList);
  const departmentList = useSelector((state) => state.dataLists.departmentList);

  const {
    data: getAllUsersData,
    error: getAllUsersError,
    isError: getAllUsersIsError,
    isSuccess: getAllUsersIsSuccess,
    isPending: getAllUsersIsPending,
    fetchStatus: getAllUsersFetchStatus,
    refetch,
  } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const token = await getAccessToken();
      return userGetAll({ token });
    },
  });

  const { mutate: saveUser } = useMutation({
    mutationFn: userSave,
    onSuccess: () => {
      refetch();
      alert('User saved successfully');
      setLockAddUser(false);
      setAsurite('');
      setSelectedRole('');
      setSelectedCollege('');
      setSelectedDepartments([]);
      setDarsSelectedColleges([]);
      setNumberOfDepartments(1);
      setNumberOfDarsColleges(1);
      setShowColleges(false);
      setShowDepartments(false);
    },
    onError: (error) => {
      console.log(error);
      alert('Error saving user');
      setLockAddUser(false);
      setAsurite('');
      setSelectedRole('');
      setSelectedCollege('');
      setSelectedDepartments([]);
      setDarsSelectedColleges([]);
      setNumberOfDepartments(1);
      setNumberOfDarsColleges(1);
      setShowColleges(false);
      setShowDepartments(false);
    },
  });

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    setSelectedCollege('');
    setNumberOfDepartments(1);
    setSelectedDepartments([]);
    setNumberOfDarsColleges(1);
    setDarsSelectedColleges([]);

    if (
      e.target.value === 'COLLEGE' ||
      e.target.value === 'DEPARTMENT' ||
      e.target.value === 'DARS'
    )
      setShowColleges(true);
    else setShowColleges(false);

    if (e.target.value === 'DEPARTMENT') setShowDepartments(true);
    else setShowDepartments(false);
  };

  const handleCollegeChange = (e) => {
    setSelectedCollege(e.target.value);
    setNumberOfDepartments(1);
    setSelectedDepartments([]);

    if (selectedRole === 'DEPARTMENT') setShowDepartments(true);
    else setShowDepartments(false);
  };

  const handleDarsCollegeChange = (e, index) => {
    const { value } = e.target;
    const updatedUserColleges = [...selectedDarsColleges];

    updatedUserColleges[index] = value;

    setDarsSelectedColleges(updatedUserColleges);
    setNumberOfDepartments(1);
    setSelectedDepartments([]);

    if (selectedRole === 'DEPARTMENT') setShowDepartments(true);
    else setShowDepartments(false);
  };

  const handleAddDarsCollege = () => {
    setNumberOfDarsColleges(numberOfDarsColleges + 1);
    setDarsSelectedColleges([...selectedDarsColleges, '']);
  };

  const handleRemoveDarsCollege = (index) => {
    const updatedUserColleges = [...selectedDarsColleges];

    updatedUserColleges.splice(index, 1);

    setDarsSelectedColleges(updatedUserColleges);
    setNumberOfDarsColleges(numberOfDarsColleges - 1);
  };

  const handleDepartmentChange = (e, index) => {
    const { value } = e.target;
    const updatedUserDepartments = [...selectedDepartments];

    updatedUserDepartments[index] = value;

    setSelectedDepartments(updatedUserDepartments);
  };

  const handleAddDepartment = () => {
    setNumberOfDepartments(numberOfDepartments + 1);
    setSelectedDepartments([...selectedDepartments, '']);
  };

  const handleRemoveDepartment = (index) => {
    const updatedUserDepartments = [...selectedDepartments];

    updatedUserDepartments.splice(index, 1);

    setSelectedDepartments(updatedUserDepartments);
    setNumberOfDepartments(numberOfDepartments - 1);
  };

  const handleSaveUser = async (e) => {
    e.preventDefault();

    const token = await getAccessToken();

    const data = {
      asurite: asurite,
      roles: JSON.stringify([selectedRole]),
      college:
        selectedRole === 'DARS'
          ? JSON.stringify(selectedDarsColleges.filter((n) => n !== ''))
          : selectedCollege || undefined,
      department: JSON.stringify(selectedDepartments.filter((n) => n !== '')),
    };

    if (asurite && selectedRole) {
      setLockAddUser(true);
      saveUser({ jsonData: data, token });
    } else alert('Please fill out all fields');
  };

  if (getAllUsersIsPending && getAllUsersFetchStatus === 'fetching')
    return <div className="container">Loading...</div>;

  if (getAllUsersIsError)
    return (
      <div>
        <div>An error has occurred: {getAllUsersError.message}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <div className="container">
      <h3>Users</h3>
      <form
        onSubmit={(e) => {
          handleSaveUser(e);
        }}
      >
        <div className="row mb-2">
          <div className="col">
            <label htmlFor="asurite">Asurite</label>
            <input
              type="text"
              id="asurite"
              className="form-control"
              value={asurite}
              onChange={(e) => setAsurite(e.target.value)}
              required
            />
          </div>
          <div className="col">
            <label htmlFor="role">Role</label>
            <select
              id="role"
              className="form-control"
              value={selectedRole}
              onChange={handleRoleChange}
              required
            >
              <option value="" disabled>
                Select a Role
              </option>
              {StaticData.userRoles.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col">
            {showColleges && (
              <div>
                <label htmlFor="college">College</label>
                {selectedRole === 'DARS' ? (
                  <>
                    {[...Array(numberOfDarsColleges)].map(
                      (selectable, index) => (
                        <div
                          key={index}
                          className="d-flex gap-1 align-items-center mb-1"
                        >
                          <select
                            id="college"
                            className="form-control"
                            value={selectedDarsColleges[index]}
                            onChange={(e) => handleDarsCollegeChange(e, index)}
                          >
                            <option value="">Select a College</option>
                            {Object.entries(collegeList)
                              .filter(
                                ([code, obj]) =>
                                  code !== 'COE' &&
                                  code !== 'CGC' &&
                                  code !== 'CLW' &&
                                  code !== 'CTPH' &&
                                  code !== 'CPROVOST'
                              )
                              .toSorted(([aCode, aObj], [bCode, bObj]) =>
                                aObj.alphaSortDescription.localeCompare(
                                  bObj.alphaSortDescription
                                )
                              )
                              .map(([code, obj]) => (
                                <option key={code} value={code}>
                                  {obj.alphaSortDescription}
                                </option>
                              ))}
                          </select>
                          {numberOfDarsColleges > 1 && (
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => handleRemoveDarsCollege(index)}
                            />
                          )}
                        </div>
                      )
                    )}
                    <div
                      className="d-flex gap-1 align-items-center text-maroon underline-hover"
                      onClick={handleAddDarsCollege}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                      Add additional college
                    </div>
                  </>
                ) : (
                  <select
                    id="college"
                    className="form-control"
                    value={selectedCollege}
                    onChange={(e) => handleCollegeChange(e)}
                  >
                    <option value="">Select a College</option>
                    {Object.entries(collegeList)
                      .filter(
                        ([code, obj]) =>
                          code !== 'COE' &&
                          code !== 'CGC' &&
                          code !== 'CLW' &&
                          code !== 'CTPH' &&
                          code !== 'CPROVOST'
                      )
                      .toSorted(([aCode, aObj], [bCode, bObj]) =>
                        aObj.alphaSortDescription.localeCompare(
                          bObj.alphaSortDescription
                        )
                      )
                      .map(([code, obj]) => (
                        <option key={code} value={code}>
                          {obj.alphaSortDescription}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            )}
          </div>

          <div className="col">
            {showDepartments && (
              <div>
                <label htmlFor="department">Department</label>
                {[...Array(numberOfDepartments)].map((selectable, index) => (
                  <div
                    key={index}
                    className="d-flex gap-1 align-items-center mb-1"
                  >
                    <select
                      id="department"
                      className="form-control"
                      value={selectedDepartments[index]}
                      onChange={(e) => handleDepartmentChange(e, index)}
                    >
                      <option value="">Select a Department</option>
                      {!!selectedCollege &&
                        !!collegeList[selectedCollege]?.departments &&
                        Object.entries(collegeList[selectedCollege].departments)
                          .toSorted(([aCode, aObj], [bCode, bObj]) =>
                            aObj.description.localeCompare(bObj.description)
                          )
                          .map(([code, obj]) => (
                            <option key={code} value={code}>
                              {obj.description}
                            </option>
                          ))}
                    </select>
                    {numberOfDepartments > 1 && (
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleRemoveDepartment(index)}
                      />
                    )}
                  </div>
                ))}
                <div
                  className="d-flex gap-1 align-items-center text-maroon underline-hover"
                  onClick={handleAddDepartment}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                  Add additional department
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <button
              className="btn btn-primary"
              disabled={
                lockAddUser ||
                !asurite ||
                !selectedRole ||
                (selectedRole === 'COLLEGE' && !selectedCollege) ||
                (selectedRole === 'DEPARTMENT' &&
                  (selectedDepartments.length === 0 ||
                    !selectedDepartments[0])) ||
                (selectedRole === 'DARS' &&
                  (selectedDarsColleges.length === 0 ||
                    !selectedDarsColleges[0]))
              }
            >
              Add user
            </button>
          </div>
        </div>
      </form>
      {getAllUsersIsSuccess && (
        <div className="row">
          <div className="col">
            <h4>User List</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>ASURITE</th>
                  <th>ROLE</th>
                  <th>COLLEGE</th>
                  <th>DEPARTMENT</th>
                  <th className="text-end">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {getAllUsersData.map((item, i) => (
                  <UserRow
                    key={i}
                    item={item}
                    collegeList={collegeList}
                    departmentList={departmentList}
                    refetch={refetch}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

const UserRow = ({ item, collegeList, departmentList, refetch }) => {
  const { getAccessToken } = useServiceAuth();

  const { mutate: deleteUser } = useMutation({
    mutationFn: userDelete,
    onSuccess: () => {
      refetch();
      alert('User deleted successfully');
    },
  });

  const handleDeleteUser = async (e) => {
    const token = await getAccessToken();

    if (window.confirm(`Are you sure you want to delete user ${item.sk.S}?`))
      deleteUser({ id: item.sk.S, token });
  };

  return (
    <tr>
      <td>{item.sk.S}</td>
      <td>{!!item?.roles?.S && JSON.parse(item.roles.S)[0]}</td>

      <td>
        {item?.college?.S &&
        item.college.S !== 'undefined' &&
        item.college.S !== '[]' ? (
          <div>
            {item.roles.S.includes('DARS') ? (
              JSON.parse(item.college.S).map(
                (coll, index) =>
                  !!coll && (
                    <div key={index}>
                      {!!collegeList[coll]
                        ? collegeList[coll].description
                        : '{College not found}'}{' '}
                      ({coll})
                    </div>
                  )
              )
            ) : (
              <>
                {!!collegeList[item.college.S]
                  ? collegeList[item.college.S].description
                  : '{College not found}'}{' '}
                ({item.college.S})
              </>
            )}
          </div>
        ) : (
          '\u2014'
        )}
      </td>
      <td>
        {!!item?.department?.S && item.department.S !== '[]'
          ? JSON.parse(item.department.S).map(
              (dept, index) =>
                !!dept && (
                  <div key={index}>
                    {!!departmentList[dept]
                      ? departmentList[dept].description
                      : '{Department not found}'}{' '}
                    ({dept})
                  </div>
                )
            )
          : '\u2014'}
      </td>
      <td>
        <div className="text-end">
          {item.sk.S !== 'lcabre' && (
            <button
              className="btn btn-sm btn-gray"
              onClick={() => {
                handleDeleteUser();
              }}
            >
              Delete
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default Users;
